@import "styles/variables";
@import "styles/mixins";

.container {
  position: relative;
  padding-top: 4.5rem;

  :global {
    .ReactCollapse--collapse {
      position: relative;
      min-height: 40rem;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 12rem;
        background-image: linear-gradient(0deg, #FFFFFF 46.04%, rgba(255, 255, 255, 0) 100%);
        background-size: cover;
        opacity: 1;
        pointer-events: all;
        transition-duration: 0.3s;
        z-index: 1;
      }
    }
  }

  .collapseOpened {
    min-height: 40rem;
    transition: height 0.3s;

    &::before {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.list {
  @include resetList;

  counter-reset: advCards;
}

.item {
  position: relative;
  margin-top: 6rem;

  &::before {
    counter-increment: advCards;
    content: counter(advCards, decimal-leading-zero);
    position: absolute;
    top: -5.5rem;
    left: 0;
    @include h0(var(--color-highlight-lightest));
    z-index: -1;
  }
}

.subtitle {
  @include capitals-bold(var(--color-font-main));

  margin: 0;
}

.descriptor {
  @include regular(var(--color-font-main));

  margin: 0.7rem 0 0 0;
}

.btn {
  @include resetButton;
  @include capitals-bold(var(--color-highlight-buttons));
  @include colorButtonBehavior;

  position: absolute;
  bottom: 2rem;
  left: 1.5rem;
  display: flex;
  align-items: center;
  z-index: 1;
}

.btnOpened {
  display: none;
}

.icon {
  margin-left: 0.7rem;
  width: 0.8rem;
  stroke: var(--color-highlight-buttons);
  transform: rotate(180deg);
}

@media (min-width: $widthTabletMin) {
  .container {
    padding-top: 5rem;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 6.5rem 3rem;
    margin-top: 0.5rem;
  }

  .item {
    margin-top: 0;
  }
}
