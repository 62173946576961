@import "styles/mixins";
@import "styles/variables";

.buttonReview {
  @include resetButton;
  @include capitals-bold($colorMainBg);

  grid-column: 2 / 3;
  width: max-content;
  margin-top: 3.3rem;
  transition-duration: $transitionDuration;

  &:hover {
    color: var(--color-font-main);

    .bannerIcon {
      stroke: var(--color-font-main);
    }
  }
}

.bannerIcon {
  margin-left: 1rem;
  width: 1.5rem;
  stroke: $colorMainBg;
  transition-duration: $transitionDuration;
}

.container {
  padding-top: 3.8rem;
  padding-bottom: 11rem;
}
