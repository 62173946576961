@import "styles/variables";
@import "styles/mixins";

.button {
  @include resetButton;
  @include h9-regular(var(--color-highlight-lightest));

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition-duration: 0.3s;
  background-size: cover;
  cursor: pointer;

  .icon {
    width: 5.7rem;

    :global {
      .circle__outer {
        stroke: var(--color-highlight-lightest);
        transition-duration: 0.3s;
      }

      .circle__inner {
        fill: var(--color-highlight-lightest);
        transition-duration: 0.3s;
      }

      .icon__play {
        fill: var(--color-highlight-mosaic);
        transition-duration: 0.3s;
      }
    }
  }

  .label {
    margin-top: 0.9rem;
  }
}

.buttonMinified {
  width: 1.8rem;

  .icon {
    width: 100%;

    :global {
      .circle__outer {
        display: none;
      }

      .circle__inner {
        stroke: var(--color-highlight-mosaic);
        transition-duration: 0.3s;
      }
    }
  }
}

@media (min-width: $widthTabletMin) {
  .buttonMinified {
    width: 2.3rem;
  }
}
