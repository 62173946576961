@import "styles/variables";
@import "styles/mixins";

.wrapper {
  position: relative;
  height: 100%;

  textarea {
    height: 100%;
    resize: none;
  }
}

.input {
  @include regular($colorMainBg);

  padding: 0 2.5rem;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.08);
  outline: none;
  transition-duration: $transitionDuration;
  transition-property: background-color, outline, color;
  zoom: 0;

  &::placeholder {
    color: $colorMainBg;
    opacity: 0.5;
  }

  &:focus {
    background-color: rgba(255, 255, 255, 0.15);
  }
}

.inputError {
  color: var(--color-highlight-buttons);
  border: 1px solid var(--color-highlight-mosaic);
}

.inputLight {
  color: var(--color-font-main);
  border: 1px solid var(--color-highlight-darker);

  &::placeholder {
    color: var(--color-font-main);
  }

  &:hover {
    border-color: rgba(70, 75, 86, 0.25)
  }
}

.inputDark {
  color: var(--color-font-main);
  border: 1px solid var(--color-highlight-darker);

  &::placeholder {
    color: var(--color-font-main);
  }

  &:hover {
    border-color: rgba(70, 75, 86, 0.25)
  }
}

.inputSearch {
  padding-left: 6.1rem;
}

.iconSearch {
  position: absolute;
  top: 2rem;
  left: 2.8rem;
  width: 1.9rem;
  fill: var(--color-font-main);
  opacity: 0.5;
}
