@import "styles/mixins";
@import "styles/variables";

.wrapper {
  position: relative;

  :global {
    .swiper-container-horizontal {
      padding-bottom: 3.5rem;
    }

    .swiper-slide {
      height: 100% !important;
    }

    div.swiper-pagination {
      bottom: 0.3rem;
      top: auto;
      margin-left: 7.5rem;
      width: 25.8rem;

      &.swiper-pagination-progressbar {
        height: 0.3rem;
        background-color: transparent;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: var(--color-highlight-darkest);
        }

        .swiper-pagination-progressbar-fill {
          background-color: var(--color-font-main);
        }
      }
    }
  }
}

.controls {
  position: absolute;
  bottom: -0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  z-index: 1;
}

.active,
.total {
  @include h9-regular(var(--color-font-main));

  display: inline-block;
  min-width: 1.5rem;
}

.total {
  &::before {
    content: '/';
    @include h9-regular(var(--color-font-main));
    margin: 0 0.8rem 0 1rem;
  }
}

.buttons {
  display: flex;
}

.btn {
  @include resetButton;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  cursor: pointer;

  &:hover {
    .icon {
      stroke: var(--color-highlight-buttons);
    }
  }
}

.btnNext {
  margin-left: 0.7rem;
  transform-origin: center;

  .icon {
    transform: rotate(90deg);
  }
}

.btnDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.icon {
  width: 1rem;
  stroke: var(--color-font-main);
  transform-origin: center;
  transform: rotate(-90deg);
  transition-property: stroke;
  transition-duration: 0.3s;
  pointer-events: none;
}

.buttonsStyled {
  position: absolute;
  top: -8rem;
  right: 0;
  display: flex;
  z-index: 1;
}
