@import "styles/variables";
@import "styles/mixins";

.wrapper {
  padding-bottom: 6rem;
}

.breadcrumbs {
  margin-top: calc(7.3rem + 1.2rem);
}

.title {
  margin-top: 1.4rem;
}

.wyziwig {
  :global {
    .wrapper {
      & > div:not(.two-columns):not(.image-text) {
        margin-top: 4rem;
      }
    }

    .two-columns {
      margin-top: 2.1rem;
    }

    .column {
      p {
        @include regularTabletScaling(var(--color-font-main));

        margin: 0;

        &:not(:first-child) {
          margin-top: 1.3rem;
        }
      }
    }

    .image-text {
      img {
        margin-left: calc(-1 * var(--padding-mobile));
        width: calc(100% + var(--padding-mobile) * 2);
        height: 23.5rem;
        object-fit: cover;
      }

      h3 {
        margin-top: 1.9rem;
      }
    }

    .italic {
      @include h5-italic(var(--color-font-main));

      & + p {
        margin-top: 1.2rem;
      }
    }
  }

  blockquote {
    @include h4($colorMainBg);

    position: relative;
    margin: 4.2rem 0 0 calc(-1 * var(--padding-mobile));
    padding: 2.4rem 5rem 3rem 3rem;
    width: calc(100% + var(--padding-mobile) * 2);
    background-color: var(--color-highlight-mosaic);
    border-top-right-radius: 4rem;
    border-top-left-radius: 4rem;

    &::before {
      content: "";
      position: absolute;
      top: 2.3rem;
      right: 2.3rem;
      width: 2.4rem;
      height: 2.1rem;
      background-image: url("../../assets/icons/icon_quotes.svg");
      background-size: cover;
    }
  }

  h3 {
    @include h2(var(--color-font-main));

    margin: 0;
  }

  p {
    @include regularTabletScaling(var(--color-font-main));

    margin: 2.2rem 0 0 0;
  }

  ul {
    @include resetList;

    margin-top: 4.2rem;

    li {
      @include regularTabletScaling(var(--color-font-main));

      position: relative;

      &::before {
        content: "";
        display: inline-block;
        margin-bottom: 0.3rem;
        width: 0.3rem;
        height: 0.3rem;
        background-color: var(--color-font-main);
        border-radius: 50%;

      }

      &:not(:first-child) {
        margin-top: 2.2rem;
      }
    }
  }
}

.banner {
  margin-top: 4.2rem;
  margin-left: calc(-1 * var(--padding-mobile));
  width: calc(100% + var(--padding-mobile) * 2);
  height: 23.5rem;
  object-fit: cover;
}

.seeMore {
  display: none;
}

@media (min-width: $widthTabletMin) {
  .breadcrumbs {
    margin-top: calc(7.3rem + 2.9rem);
  }

  .title {
    margin-top: 2.5rem;
  }

  .wyziwig {
    blockquote {
      @include h4($colorMainBg);

      position: relative;
      margin: 4rem 0 0 0;
      padding: 3.9rem 3.2rem 5rem 8rem;
      width: 100%;
      background-color: var(--color-highlight-mosaic);
      border-top-right-radius: 0;
      border-top-left-radius: 0;

      &::before {
        top: 3.5rem;
        left: 3.2rem;
        width: 3.2rem;
        height: 2.8rem;
      }
    }

    :global {
      .image-text {
        margin-top: 5rem;

        img {
          float: left;
          margin-right: 3rem;
          margin-left: 0;
          width: 28.2rem;
          height: 25.1rem;
          object-fit: cover;
        }

        h3 {
          margin-top: 0;
        }
      }
    }
  }

  .banner {
    margin-top: 2.5rem;
    margin-left: calc(-1 * var(--padding-tablet));
    width: calc(100% + var(--padding-tablet) * 2);
    height: 31.2rem;
  }

  .seeMore {
    display: block;
    margin-top: 5rem;
  }
}
