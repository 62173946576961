@import "styles/variables";
@import "styles/mixins";

.section {
  padding: 5rem 0 4rem 0;

  :global {
    .swiper-container {
      margin-left: 0;
      padding-right: 0;
      padding-left: 0;
      width: 100%;
      overflow: visible;
    }

    .swiper-slide {
      width: 100%;
      margin-right: 0;
    }
  }
}

.slider {
  position: relative;
  margin-top: 5rem;
}

.cardContent {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;
}

.slide {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: $colorMainBg;
}

.tabContent {
  width: 87.7rem;
}

.frame {
  order: 2;
  position: relative;
  width: 47.6rem;
  height: 47.6rem;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.3rem;
    background-color: var(--color-highlight-mosaic);
    z-index: 1;
  }
}

.iconDots {
  position: absolute;
  top: 0;
  right: calc(50% - 25rem);
  display: block;
  width: 47.6rem;
  opacity: 0.3;
  transform-origin: center;
  transform: rotate(90deg);
}

.photo {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.info {
  order: 1;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  padding-right: 2rem;
  width: calc(100% - 47.6rem);
  min-height: 47.6rem;
}

.name {
  @include h2-adaptiveSpecial(var(--color-font-main));

  margin: 0;
}

.expertise {
  @include regular(var(--color-font-main));

  margin: 1.4rem 0 0 0;
  opacity: 0.7;
}

.address {
  @include regularTabletScaling(var(--color-font-main));

  margin: 1.4rem 0 0 0;
}

.quote {
  @include h5-italic(var(--color-font-main));

  position: relative;
  display: block;
  margin: 3rem 0;
  padding: 0;

  span {
    opacity: 0.7;
  }
}

.iconQuote {
  position : absolute;
  top: 0;
  left: calc(-3.2rem - 2.1rem);
  width: 3.2rem;
  fill: var(--color-font-main);
  opacity: 0.5;
}

.btnAll {
  display: inline-block;
  margin-top: auto;
  width: 25.5rem;
}

.tabs {
  @include resetList
}

.tab {
  &:not(:first-of-type) {
    margin-top: 1rem;
  }
}

.tabSwitch {
  @include resetButton;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.7rem 3.3rem 2.5rem 4.3rem;
  width: 43.7rem;
  border: 1px solid var(--color-highlight-darker);
  cursor: pointer;
  transition-duration: 0.3s;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -0.3rem;
    width: 0.3rem;
    height: 100%;
    background-color: var(--color-highlight-mosaic);
    opacity: 0;
    pointer-events: none;
    transition-duration: 0.3s;
  }

  &:hover {
   border-color: var(--color-highlight-buttons);
  }
}

.tabSwitchSelected {
  pointer-events: none;
  box-shadow: 0 0.8rem 2rem rgba(0, 0, 0, 0.08);
  border-color: var(--color-highlight-darker);

  &::before {
    opacity: 1;
    pointer-events: all;
  }
}

.btnName {
  @include h5(var(--color-font-main));

  text-align: left;
}

.btnExpertise {
  @include regular(var(--color-font-main));

  text-align: left;
  opacity: 0.7;
}

.controls {
  position: absolute;
  top: calc(-3rem - 6.3rem);
  right: 0;
  display: flex;
  align-items: flex-start;
}

.btnNext {
  margin-left: 3rem;
}
