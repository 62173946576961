@import "styles/variables";
@import "styles/mixins";

.wrapper {
  margin-top: 4.6rem;
}

.title {
  @include h2(var(--color-font-main));

  margin: 0;
}

.slider {
  margin-top: 2.7rem;
  margin-left: calc(-1 * var(--padding-mobile));
  width: calc(100% + 2 * var(--padding-mobile));

  :global {
    .swiper-container {
      padding-left: var(--padding-mobile);
    }

    .swiper-slide {
      width: 27rem;
    }

    .swiper-pagination {
      left: var(--padding-mobile);
      width: calc(100% - 2 * var(--padding-mobile));
    }
  }
}

.slide {
  display: flex;
  align-items: flex-start;
  overflow: hidden;
}

.card {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 5rem;
    left: 3rem;
    width: 43rem;
    height: 35.1rem;
    background-image: url("../../../assets/icons/doctor__photo-bg.svg");
    background-size: cover;
  }
}

.photo {
  position: relative;
  width: 100%;
  height: 27rem;
  object-fit: cover;
}

.name {
  @include h5(var(--color-font-main));

  margin: 2rem 0 0 0;
}

.expertise {
  @include regular(var(--color-font-main));

  margin: 0;
  opacity: 0.7;
}

.address {
  @include regular(var(--color-font-main));

  margin: 1rem 0 0 0;
}

@media (min-width: $widthTabletMin) {
  .wrapper {
    margin-top: 6.1rem;
  }

  .slider {
    margin-top: 2.5rem;
    margin-left: 0;

    :global {
      .swiper-container {
        padding-left: var(--padding-tablet);
      }

      .swiper-slide {
        width: 31.1rem;

        &:last-child {
          margin-right: var(--padding-tablet);
        }
      }

      div.swiper-pagination {
        left: 0;
        width: calc(100% - 3 * var(--padding-tablet));
      }
    }
  }

  .slide {
    display: flex;
    align-items: flex-start;
  }

  .photo {
    width: 100%;
    height: 31.9rem;
    object-fit: cover;
  }

  .name {
    margin: 1rem 0 0 0;
    font-size: 2rem;
    line-height: 3rem;
  }

  .expertise {
    @include regular(var(--color-font-main));

    margin: 0;
    opacity: 0.7;
  }

  .address {
    @include regular(var(--color-font-main));

    margin: 1rem 0 0 0;
  }

}
