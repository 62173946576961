@import "styles/variables";
@import "styles/mixins";

.wrapper {
  width: 60rem;
  background-color: $colorMainBg;
}

.wrapperIllustrated {
  display: grid;
  grid-template-columns: 29.3rem 60rem;
  width: 89.3rem;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  padding: 4.2rem 6rem;
}

.descriptor {
  @include regular(var(--color-font-main));

  margin: 1.8rem 0 0 0;
}

.form {
  display: grid;
  grid-template-rows: repeat(2, 6rem);
  grid-gap: 2rem;
  margin-top: 3.6rem;
}

.fieldset {
  margin: 0;
  padding: 0;
  height: 100%;
  border: none;
}

.button {
  margin-top: 4rem;
}

.acceptance {
  @include small(var(--color-font-main));

  display: grid;
  grid-template-columns: 1.6rem 1fr;
  grid-column-gap: 1rem;
  margin: 2.6rem 0 0 0;
  opacity: 0.5;

  .icon {
    width: 100%;
  }
}

.error {
  @include small(red);

  margin: 0.1rem 0 0 0;
}
