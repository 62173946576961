@import "styles/variables";
@import "styles/mixins";

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.8rem 3.3rem 3.8rem 4rem;
  min-height: 21rem;
  background-color: var(--color-highlight-lightest);

  &:hover {
    .label {
      color: var(--color-highlight-buttons);
    }
  }
}

.label {
  @include h5(var(--color-font-main));

  margin: 0 0 1rem 0;
  transition-duration: $transitionDuration;
}

.sign {
  @include h7(var(--color-highlight-buttons));

  display: flex;
  align-items: center;
  margin-top: auto;
}

.icon {
  margin-left: 1rem;
  width: 1.5rem;
  stroke: var(--color-highlight-buttons);
}
