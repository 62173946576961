@import "styles/variables";
@import "styles/mixins";

.link {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5.5rem 2rem 2rem 2rem;
  height: 100%;
  border: 1px solid var(--color-highlight-darker);
  transition-duration: $transitionDuration;

  &::before {
    content: "";
    position: absolute;
    top: 1.8rem;
    left: 2rem;
    width: 3rem;
    height: 2.8rem;
    background-image: url("../../assets/icons/icon__download.svg");
    background-size: cover;
  }

  &:hover {
    border-color: var(--color-highlight-buttons);

    .name {
      color: var(--color-highlight-buttons);
    }
  }
}

.name {
  @include h6(var(--color-font-main));

  margin: 0;
  transition-duration: $transitionDuration;
}

.info {
  @include h9-regular(var(--color-font-main));

  margin: 0.5rem 0 0 0;
  opacity: 0.5;
}

.format {
  text-transform: uppercase;
}

.size {
  margin-left: 0.5rem;
}

@media (min-width: $widthTabletMin) {
  .link {
    padding: 2.1rem 2rem 2.3rem 6.4rem;

    &::before {
      top: 2.5rem;
      left: 1.8rem;
    }
  }
}
