@import "styles/variables";
@import "styles/mixins";

.section {
  margin-top: 1.6rem;
  padding-bottom: 4.8rem;
}

.duration {
  @include regular(var(--color-font-main));

  margin: 0.6rem 0 0 0;
  opacity: 0.5;
}

.descriptor {
  @include regular(var(--color-font-main));

  margin: 1.8rem 0 0 0;
}

.image {
  display: block;
  margin-top: 2.2rem;
  margin-left: calc(-1 * var(--padding-mobile));
  width: calc(100% + 2 * var(--padding-mobile));
  height: 15rem;
  object-fit: contain;
}

@media (min-width: $widthTabletMin) {
  .section {
    margin-top: 2.5rem;
    padding-bottom: 5rem;
  }

  .descriptor {
    margin: 2rem 0 0 0;
  }

  .image {
    margin-top: 2.4rem;
    margin-left: 0;
    width: 100%;
    height: 28.2rem;
  }
}
