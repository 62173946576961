// color themes

:root {
  --padding-mobile: 1.5rem;
  --padding-tablet: 2.5rem;

  .color-pattern-1 {
    --color-highlight-buttons: rgb(60, 204, 93);
    --color-highlight-buttons-dark: #e64d2b;
    --color-highlight-mosaic: rgb(51, 131, 251);
    --color-font-main: #464b56;
    --color-highlight-lightest: #f7f8fB;
    --color-highlight-darker: #ebedf2;
    --color-highlight-darkest: #dcdfe6;
    --color-highlight-gradient: linear-gradient(97.35deg, #10C8D2 -4.47%, #66A1FF 96.77%);
  }

  .color-pattern-2 {
    --color-highlight-buttons: rgb(0, 0, 0);
    --color-highlight-buttons-dark: rgb(0, 0, 0);
    --color-highlight-mosaic: rgb(119, 194, 255);
    --color-font-main: rgb(0, 0, 0);
    --color-highlight-lightest: #f7f8fB;
    --color-highlight-darker: #ebedf2;
    --color-highlight-darkest: #dcdfe6;
    --color-highlight-gradient: linear-gradient(97.35deg, #10C8D2 -4.47%, #66A1FF 96.77%);
    --color-lightest: rgb(255, 255, 255);
  }

  .color-pattern-special {
    --color-highlight-buttons: black;
    --color-highlight-mosaic: black;
    --color-font-main: black;
    --color-highlight-lightest: white;
    --color-highlight-darker: white;
    --color-highlight-darkest: white;
    --color-highlight-gradient: white;
  }
}

// common colors

$colorMainBg: #fff;

// width variables

$widthMobileMax: 767px;
$widthTabletMin: 768px;
$widthTabletMax: 1279px;
$widthDesktopMin: 1280px;

// misc

$transitionDuration: 0.3s;
