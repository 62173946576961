@import "styles/variables";
@import "styles/mixins";

.wrapper {
  max-width: 60rem;
  background-color: $colorMainBg;
}

.image {
  display: none;
}

.content {
  padding: 2rem 3rem;
}

.descriptor {
  @include regular(var(--color-font-main));

  margin: 1.8rem 0 0 0;
}

.fieldset {
  margin: 0;
  padding: 0;
  border: none;

  &:first-child {
    margin-top: 2rem;
  }

  &:not(:first-child) {
    margin-top: 1rem;
  }
}

.button {
  margin-top: 2rem;
}

.acceptance {
  @include small(var(--color-font-main));

  display: grid;
  grid-template-columns: 1.5rem 1fr;
  grid-column-gap: 1rem;
  margin: 1.5rem 0 0 0;
  opacity: 0.5;

  .icon {
    width: 100%;
  }
}

.error {
  @include small(red);

  margin: 1rem 0 0 0;
}

@media (min-width: $widthTabletMin) {
  .wrapperIllustrated {
    display: grid;
    grid-template-columns: 20rem auto;

    .image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
