@import "styles/variables";
@import "styles/mixins";

.wrapper {
  position: relative;
  border: 1px solid var(--color-highlight-darker);
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 4rem;
    right: 1.8rem;
    width: 1.4rem;
    height: 1px;
    background-color: var(--color-font-main);
  }

  &::after {
    content: "";
    position: absolute;
    top: 4rem;
    right: 1.8rem;
    width: 1.4rem;
    height: 1px;
    background-color: var(--color-font-main);
    transform: rotate(90deg);
    transform-origin: center;
    transition-duration: $transitionDuration;
  }
}

.key {
  @include h6(var(--color-font-main));

  position: relative;
  margin: 0;
  padding: 2.4rem 4rem 2.4rem 2rem;
  transition-duration: $transitionDuration;
  overflow: hidden;

  span {
    position: relative;
    z-index: 1;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  transform: translateY(-100%);
  opacity: 0;
  transition-duration: 500ms;
}

.value {
  @include regular(var(--color-font-main));

  margin: 0;
  padding: 1.8rem 2rem 0 2rem;
}

.wrapperOpened {
  &::after {
    transform: rotate(0);
  }

  .key {
    color: var(--color-highlight-buttons);
  }
}

.wrapperOpenedBackground {
  &::before,
  &::after {
    background-color: $colorMainBg;
  }

  .key {
    color: $colorMainBg;
  }

  .background {
    transform: translateY(0);
    opacity: 1;
    z-index: 0;
  }
}

@media (min-width: $widthTabletMin) {
  .wrapper {
    &::before {
      right: 2.7rem;
    }

    &::after {
      right: 2.7rem;
    }
  }

  .key {
    padding: 2.5rem 8.4rem 2.2rem 3.3rem;
  }

  .value {
    padding-right: 1.2rem;
    padding-top: 1.2rem;
  }
}
