@import "styles/variables";
@import "styles/mixins";

.link {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.9rem 1.9rem 2.5rem 8.5rem;
  border: 1px solid var(--color-highlight-darker);
  transition-duration: $transitionDuration;

  &::before {
    content: "";
    position: absolute;
    top: 2.6rem;
    left: 3rem;
    width: 3rem;
    height: 2.8rem;
    background-image: url("../../assets/icons/icon__download.svg");
    background-size: cover;
  }

  &:hover {
    border-color: var(--color-highlight-buttons);

    .name {
      color: var(--color-highlight-buttons);
    }
  }
}

.name {
  @include h6(var(--color-font-main));

  margin: 0;
  transition-duration: $transitionDuration;
}

.info {
  @include h9-regular(var(--color-font-main));

  margin: 1rem 0 0 0;
  opacity: 0.5;
}

.format {
  text-transform: uppercase;
}

.size {
  margin-left: 0.5rem;
}
