@import "styles/variables";
@import "styles/mixins";

.button {
  @include resetButton;
  @include capitals(var(--color-font-main));

  display: block;
  padding: 2rem;
  width: 100%;
  text-align: center;
  border: 1px solid var(--color-highlight-darker);
  transition-duration: $transitionDuration;

  span {
    transition-duration: $transitionDuration;
  }

  &:hover {
    color: var(--color-highlight-buttons);
    border-color: var(--color-highlight-buttons);
  }

  &:active {
    border-color: var(--color-highlight-darker);

    span {
      color: var(--color-font-main);
      opacity: 0.5;
    }
  }
}
