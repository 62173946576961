@import "styles/variables";
@import "styles/mixins";

.descriptor {
  @include h5-italic(var(--color-font-main));

  margin: 1.6rem 0 0 0;
}

.list {
  @include resetList;

  display: flex;
  flex-direction: column;
  margin-top: 1.7rem;
}

.item {
  display: flex;
  align-items: center;

  &:not(:first-of-type) {
    margin-top: 3rem;
  }
}

.key {
  @include counter(var(--color-highlight-mosaic));
  margin: 0;

  display: flex;
}

.value {
  @include regular(var(--color-font-main));
  margin: 0;
}

@media (min-width: $widthTabletMin) {
  .descriptor {
    margin: 2.3rem 0 0 0;
  }

  .list {
    @include resetList;

    display: grid;
    grid-template-columns: 18rem 1fr 22rem;
    margin-top: 2.3rem;
  }

  .item {
    display: flex;
    align-items: center;

    &:nth-of-type(2n) {
      justify-content: center;
      border-right: 1px solid var(--color-highlight-darker);
      border-left: 1px solid var(--color-highlight-darker);
    }

    &:not(:first-of-type) {
      margin-top: 0;
    }

    &:last-of-type {
      padding-left: 3.2rem;
    }
  }

  .key {
    @include counter(var(--color-highlight-mosaic));
    margin: 0;

    display: flex;
  }

  .value {
    @include regular(var(--color-font-main));
    margin: 0;
  }
}
