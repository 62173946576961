@import "styles/variables";
@import "styles/mixins";

.link {
  display: flex;
  flex-direction: column;
  padding: 2rem 1.9rem;
  width: 27rem;
  border: 1px solid var(--color-highlight-darker);

  .date {
    margin: 0.6rem 0 0 0;
  }
}

.icon {
  margin-top: 1.8rem;
}

@media (min-width: $widthTabletMin) {
  .link {
    padding: 3.4rem 3.4rem 3rem 3.3rem;
    width: 33.2rem;

    .date {
      margin: 0.3rem 0 0 0;
    }
  }

  .icon {
    margin-top: 3.1rem;
  }
}
