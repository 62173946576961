@import "styles/variables";
@import "styles/mixins";

.wrapper {
  position: relative;
}

.input {
  @include regular($colorMainBg);

  padding: 1.2rem 1.6rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.08);
  outline: none;
  transition-duration: $transitionDuration;
  transition-property: background-color, outline, color;
  zoom: 0;

  &::placeholder {
    color: $colorMainBg;
    opacity: 0.5;
  }

  &:focus {
    background-color: rgba(255, 255, 255, 0.15);
  }
}

.inputError {
  color: var(--color-highlight-buttons);
  border: 1px solid var(--color-highlight-mosaic);
}

.inputLight {
  color: var(--color-font-main);
  border: 1px solid var(--color-highlight-darkest);

  &::placeholder {
    color: var(--color-font-main);
  }
}

.inputSearch {
  padding-left: 4.7rem;
}

.iconSearch {
  position: absolute;
  top: 1.5rem;
  left: 1.9rem;
  width: 1.9rem;
  fill: var(--color-font-main);
  opacity: 0.5;
}
