@import "styles/variables";
@import "styles/mixins";


.list {
  @include resetList;
}

.item {
  &:not(:first-child) {
    margin-top: 4rem;
  }
}

@media (min-width: $widthTabletMin) {
  .item {
    &:not(:first-child) {
      margin-top: 4.8rem;
    }
  }
}
