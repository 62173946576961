@import "styles/mixins";
@import "styles/variables";

.wrapper {
  :global {
    .swiper-container-horizontal {
      padding-bottom: 2.3rem;
    }

    .swiper-slide {
      width: 29rem;

      &:not(:last-of-type) {
        margin-right: 2rem;
      }
    }

    div.swiper-pagination {
      bottom: 0;
      top: auto;

      &.swiper-pagination-progressbar {
        height: 0.3rem;
        background-color: transparent;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: var(--color-highlight-darkest);
        }

        .swiper-pagination-progressbar-fill {
          background-color: var(--color-font-main);
        }
      }
    }
  }
}

.controls,
.buttonsStyled {
  display: none;
}

@media (min-width: $widthTabletMin) {
  .wrapper {
    :global {
      .swiper-container-horizontal {
        margin-left: -2.5rem;
        padding-right: 2.5rem;
        padding-left: 2.5rem;
        padding-bottom: 2.6rem;
        width: calc(100% + 5rem);
      }

      div.swiper-pagination {
        margin-left: 2.5rem;
        width: calc(100% - 5rem);
      }
    }
  }
}
