@import "styles/variables";
@import "styles/mixins";


.button {
  @include resetButton;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  transition-duration: 0.3s;
  cursor: pointer;

  &.buttonFilled {
    @include capitals-bold($colorMainBg);

    padding: 2.2rem 0 1.8rem 0;
    background-color: var(--color-highlight-buttons);
  }

  &.buttonDecorated {
    @include capitals-bold(var(--color-highlight-buttons-dark));

    position: relative;
    padding: 2rem 1rem;
    border: 0.1rem solid var(--color-highlight-buttons-dark);
    border-bottom-width: 0.5rem;
    background-color: white;
    overflow: hidden;
    z-index: 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% + 0.4rem);
      background-color: var(--color-highlight-buttons-dark);
      transition-duration: 0.3s;
      transform: translateX(-100%);
      z-index: -1;
    }

    &:hover {
      color: $colorMainBg;

      &::before {
        transform: translateX(0);
      }

      .icon {
        stroke: $colorMainBg;
        transform: translateX(1rem);
      }
    }

    .icon {
      margin-left: 1rem;
      width: 1.6rem;
      stroke: var(--color-highlight-buttons-dark);
      transition-duration: 0.3s;
      transform: translateX(0);
    }
  }

  &.buttonFilledDecorated {
    color: var(--color-highlight-buttons-dark);
    background-color: transparent;
    border: none;
    border-bottom: 0.5rem solid var(--color-highlight-buttons-dark);
    transition-duration: $transitionDuration;

    &::before {
      display: none;
    }

    &:hover {
      background-color: var(--color-highlight-buttons-dark);
    }

    .icon {
      stroke: var(--color-highlight-buttons-dark);
    }
  }

  &.buttonGradient {
    @include capitals-bold($colorMainBg);

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4.6rem 4.3rem 4.4rem 5rem;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-highlight-mosaic);
      z-index: -1;
      transition-duration: 0.3s;
      transform: translateX(-29rem);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-highlight-mosaic);
      z-index: -1;
      opacity: 0.5;
    }

    &:hover {
      &::before {
        transform: translateX(0);
      }
    }

    .icon {
      margin-left: 1rem;
      width: 1.6rem;
      stroke: $colorMainBg;
      transition-duration: 0.3s;
      transform: translateX(0);
    }
  }
}

.buttonLoading {
  pointer-events: none;
  opacity: 0.5;
}

.spinner {
  margin-left: 1rem;
  width: 1.6rem;

  circle {
    fill: var(--color-highlight-buttons);
  }

  path {
    stroke: var(--color-highlight-buttons);
  }
}

:global {
  .color-pattern-2 {
    :local {
      .buttonFilledDecorated {
        border-bottom-color: var(--color-highlight-buttons-dark);
      }
    }
  }
}