@import "styles/variables";
@import "styles/mixins";

.list {
  @include resetList;

  border-left: 1px solid var(--color-highlight-darkest);
}

.button {
  @include resetButton;
  @include h9(var(--font-color-main));

  display: flex;
  align-items: center;
  padding-left: 2.8rem;
  height: 3.8rem;
  opacity: 0.5;
}

.buttonActive {
  position: relative;
  font-weight: 600;
  opacity: 1;
  transition-duration: $transitionDuration;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -0.2rem;
    width: 0.3rem;
    height: 100%;
    background-color: var(--color-highlight-mosaic);
    z-index: 1;
  }
}
