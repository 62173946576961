@import "styles/variables";
@import "styles/mixins";

.wrapper {
  position: relative;
  width: 100%;
  background-color: black;
}

.videoWrapper {
  position: absolute;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(59, 59, 59);
    opacity: 0.6;
  }
}

.videoBg {
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
  object-position: center;
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 8.5rem;
  min-height: 100vh;
  color: white;
  background-color: transparent;
  z-index: 2;
}

.content {
  position: relative;
  margin-left: 24rem;
  //width: 48rem;

  &::before {

  }
}

.heading {
  @include h0(var(--color-lightest));

  width: 70rem;
}

.description {
  @include regular(var(--color-lightest));

  margin: 6.4rem 0 0 0;
}

.bannerText {
  @include bannerText(var(--color-lightest));

  position: absolute;
  bottom: -0.5rem;
  left: -20rem;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  opacity: 0.2;
  letter-spacing: -4px;
}

.button {
  @include resetButton;

  position: absolute;
  bottom: 20rem;
  right: -18rem;
  width: 11.5rem;
  height: 11.5rem;
  border: 1px solid var(--color-lightest);
  border-radius: 50%;
  transition-property: border-color;
  transition-duration: $transitionDuration;

  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 2rem / 2);
    left: calc(50% - 1.2rem / 2);
    width: 0;
    height: 0;
    border-top: 1.1rem solid transparent;
    border-bottom: 1.1rem solid transparent;
    border-left: 1.8rem solid var(--color-lightest);
    transition-property: border-color;
    transition-duration: $transitionDuration;
  }

  &::after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    border: 1px solid var(--color-lightest);
    border-radius: 50%;
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  &:hover {
    border-color: var(--color-highlight-mosaic);

    &::before {
      border-left-color: var(--color-highlight-mosaic);
    }
  }
}

.video {
  width: 100%;
  height: 67.2rem;
  object-fit: cover;
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(3);
  }
}