@import "styles/variables";
@import "styles/mixins";

.container {
  display: grid;
  grid-template-columns: 38rem 32rem 67rem;
  grid-row-gap: 6.8rem;
  padding-top: 5rem;

  .linkAll {
    display: flex;
    justify-content: center;
    margin-top: 2.9rem;
    width: 21.7rem;
  }
}

.title {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.descriptor {
  @include h5-italic(var(--color-font-main));

  grid-column: 2 / -1;
  grid-row: 1 / 2;
  margin: 0;
}

.feature {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  display: block;
  padding-right: 8.8rem;
}

.subdescriptor {
  p {
    @include regular(var(--color-font-main));

    margin: 0;
    text-align: justify;

    &:first-of-type {
      margin-top: 2.5rem;
    }

    &:not(:first-of-type) {
      margin-top: 3.2rem;
    }
  }
}

.offices {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
}

.subtitle {
  @include h2(var(--color-font-main));

  grid-column: 1 / -1;
  grid-row: 1 / 2;
  margin: 0;
}

.list {
  @include resetList;

  grid-column: 1 / 2;
  grid-row: 2 / 3;
  margin-top: 3rem;
  padding-left: 1.5rem;
  padding-right: 20%;
}

.item {
  @include regular(var(--color-font-main));

  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: calc(1.4rem - 0.5rem / 2);
    left: -1.5rem;
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--color-highlight-buttons);
    border-radius: 50%;
  }

  &:not(:first-of-type) {
    margin-top: 1.4rem;
  }
}

.mapContainer {
  margin-top: 8rem;
}

.linkAll {
  grid-row: 2 / 3;
}

.map {
  width: 100%;
  height: 65rem;
}
