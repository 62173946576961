@import "styles/variables";
@import "styles/mixins";

.section {
  .slider {
    margin-top: 4.5rem;

    :global {
      div.swiper-pagination.swiper-pagination-progressbar {
        width: 87.6rem;
      }
    }
  }

  .image {
    width: 100%;
    height: 55rem;
    object-fit: cover;
  }
}
