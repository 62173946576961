.content {
  margin-top: 4.4rem;
}

.gallery {
  margin-top: 1.6rem;
}

.principles {
  margin-top: 7.2rem;
}

.stats {
  margin-top: 8.3rem;
}
