@import "styles/variables";
@import "styles/mixins";

.list {
  @include resetList;
}

.item {
  &:not(:first-of-type) {
    margin-top: 1rem;
  }
}

.buttonMore {
  margin-top: 2rem
}

@media (min-width: $widthTabletMin) {
  .buttonMore {
    margin-top: 4rem
  }
}
