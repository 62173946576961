@import "../../styles/variables";
@import "../../styles/mixins";

.section:not(.sectionSimple) {
  margin-top: 5rem;
  padding-top: 2.9rem;
  padding-bottom: 3rem;
  overflow: visible;

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-left: auto;
    width: 27.5rem;
    color: var(--color-highlight-buttons);
    background-color: $colorMainBg;
    border-top-color: var(--color-highlight-darker);
    border-right-color: var(--color-highlight-darker);
    border-left: none;

    &::before {
      transform: translateX(0) translateY(100%);
    }

    &:hover {
      color: $colorMainBg;

      &::before {
        transform: translateX(0) translateY(0);
      }

      .arrow {
        stroke: $colorMainBg;
      }
    }

    .arrow {
      stroke: var(--color-highlight-buttons);
    }
  }
}

.wrapper {
  position: relative;
  display: flex;
  padding: 0;
  background-color: var(--color-font-main);

  &::before {
    content: "";
    position: absolute;
    top: -2.9rem;
    left: -9.4rem;
    width: 42rem;
    height: 35.6rem;
    background-image: url("../../assets/images/test-banner__img.png");
    background-size: cover;
  }
}

.icon {
  position: absolute;
  bottom: -16.5rem;
  left: 11rem;
  width: 40rem;
  transform: rotate(90deg);
}

.content {
  padding: 5.4rem 7.7rem 7.6rem 36.3rem;
}

.title {
  @include h1($colorMainBg);

  position: relative;
}

.icon {
  position: absolute;
  bottom: -23rem;
  left: auto;
  right: 10rem;
  width: 50rem;
  transform: rotate(90deg);
}

.descriptor {
  @include h8(var(--color-highlight-lightest));

  margin: 3.2rem 0 0 0;
}

.sectionSimple {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: var(--color-font-main);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/dots-bg-large.png");
    background-size: cover;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    padding: 13rem 0 13.4rem 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    &::before {
      display: none;
    }

  }

  .content {
    padding: 0;
  }

  .descriptor {
    margin: 3.4rem 0 0 0;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding-right: 4rem;
    padding-left: 5rem;
    width: 35.8rem;
    height: 11rem;
    color: $colorMainBg;
    background-color: var(--color-highlight-buttons);
    border: none;
    transition-duration: 0.3s;
    transition-property: background-color;

    &::before {
      display: none;
    }

    &:hover {
      background-color: var(--color-highlight-buttons-dark);
    }

    .arrow {
      stroke: $colorMainBg;
    }
  }

  .icon {
    display: none;
  }
}
