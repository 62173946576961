@import "styles/variables";
@import "styles/mixins";

.select {
  :global {
    .simplebar-placeholder {
      display: block;
    }

    .select {
      &__control {
        height: 5rem;
        cursor: pointer;
        border-color: var(--color-highlight-darker);

        &--is-focused {
          border-color: var(--color-highlight-darker);
        }
      }

      &__value-container {
        padding: 0 1.6rem;
        height: 100%;
      }

      &__placeholder {
        @include regular(var(--font-color-main));
      }

      &__single-value {
        @include regular(var(--font-color-main));

        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }

      &__indicator-separator {
        display: none;
      }

      &__dropdown-indicator {
        padding-right: 1.6rem;
        padding-left: 1.6rem;

        svg {
          fill: var(--color-font-main);
        }
      }

      &__menu {
        border-color: var(--color-highlight-darker);
        z-index: 2;
      }

      &__option {
        @include regular(var(--font-color-main));
        transition-duration: $transitionDuration;

        &:not(:first-child) {
          border-top: 1px solid var(--color-highlight-darker);
        }

        &--is-focused {
          background-color: white;
          color: var(--color-font-main);
        }

        &--is-selected {
          position: relative;
          background-color: white;
          pointer-events: none;

          &::before {
            content: "";
            position: absolute;
            left: -0.1rem;
            top: 0;
            width: 0.3rem;
            height: 100%;
            background-color: var(--color-highlight-mosaic);
            z-index: 2;
          }
        }

        &--is-focused.select__option--is-selected {
          color: var(--color-font-main);
        }

        &:not(.select__option--is-selected) {
          cursor: pointer;
        }
      }
    }
  }
}
