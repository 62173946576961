@import "styles/mixins";

.list {
  @include resetList;

  margin-top: 1.9rem;
}

.item {
  &:not(:first-of-type) {
    margin-top: 1rem;
  }
}

.buttonMore {
  margin-top: 2rem;
}

@media (min-width: $widthTabletMin) {
  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    margin-top: 2.5rem;
  }

  .item {
    &:not(:first-of-type) {
      margin-top: 0;
    }
  }

  .buttonMore {
    margin-top: 4rem;
  }
}
