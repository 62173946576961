@import "styles/variables";
@import "styles/mixins";

.list {
  @include resetList;

  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 3.6rem;
  padding: 0 1.9rem;

  & + .buttonMore {
    margin-top: 3.6rem;
  }
}

@media (min-width: $widthTabletMin) {
  .list {
    grid-template-columns: repeat(3, 22rem);
    grid-row-gap: 3rem;
    justify-content: space-between;
    padding: 0;

    & + .buttonMore {
      margin-top: 4rem;
    }
  }
}
