@import "styles/variables";
@import "styles/mixins";

div.top {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 4.9rem;
  z-index: 1;
}

.mainSlogan {
  @include h4(var(--color-font-main));

  margin: 0 0 5.5rem 0;
  max-width: 40%;
}

.previewWrapper {
  margin-top: 1.2rem;
  margin-left: auto;
}

.previewLabel {
  @include h8(var(--color-font-main));

  margin: 0;
}

button.preview {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width: 36rem;
  height: 21.6rem;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.6rem;
    background-color: var(--color-highlight-mosaic);
  }
}

.video {
  width: 100%;
  height: 67.2rem;
  object-fit: cover;
}

.content {
  position: relative;
  margin-top: -10.6rem;
  padding-top: 15.5rem;
  padding-bottom: 18.2rem;
  background-color: transparent;
  border-radius: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-highlight-mosaic);
    opacity: 0.6;
    z-index: 0;
  }
}

.videoBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  z-index: -1;
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dots {
  position: absolute;
  top: calc(-15.7rem - 9.8rem);
  right: calc(50% - 16.7rem);
  width: 15.5rem;
}

.textContent {
  display: flex;
  flex-direction: column-reverse;
  max-width: 50%;
}

.sloganDesktop {
  @include h0($colorMainBg);

  margin: 9.8rem 0 0 0;
}

.descriptor {
  @include h5($colorMainBg);

  margin: 0;
  padding-right: 20%;
  opacity: 1;
}

.btn {
  margin-top: 18.8rem;
  width: 35.8rem;
}
