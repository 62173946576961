@import "styles/variables";
@import "styles/mixins";

.section {
  padding-bottom: 6.5rem;
}

.breadcrumbs {
  margin-top: 3.8rem;
}

.title {
  margin-top: 4.2rem;
  padding-right: 30%;
}

.wyziwig {
  :global {
    .two-columns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 3rem;
    }

    .column {
      p {
        @include regular(var(--color-font-main));

        margin: 0;

        &:not(:first-child) {
          margin-top: 3rem;
        }
      }
    }

    .image-text {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      img {
        width: 66.7rem;
        height: 59.2rem;
        object-fit: cover;
      }

      & > div {
        width: 67rem;
      }
    }

    .wrapper {
      & > div {
        margin-top: 6rem;
      }
    }

    .italic {
      @include h5-italic(var(--color-font-main));

      & + p {
        margin-top: 3.8rem;
      }
    }
  }

  blockquote {
    @include h4($colorMainBg);

    position: relative;
    margin: 6rem 0 0 0;
    padding: 5.2rem 8rem 5.6rem 8rem;
    background-color: var(--color-highlight-mosaic);

    &::before {
      content: "";
      position: absolute;
      top: 3.4rem;
      left: 3.2rem;
      width: 3.2rem;
      height: 2.8rem;
      background-image: url("../../assets/icons/icon_quotes.svg");
      background-size: cover;
    }
  }

  h3 {
    @include h2(var(--color-font-main));

    margin: 0;
    padding-right: 40%;
  }

  p {
    @include regular(var(--color-font-main));

    margin: 3rem 0 0 0;
  }

  ul {
    @include resetList;

    margin-top: 6rem;

    li {
      @include regular(var(--color-font-main));

      position: relative;

      &::before {
        content: "";
        display: inline-block;
        margin-bottom: 0.3rem;
        width: 0.3rem;
        height: 0.3rem;
        background-color: var(--color-font-main);
        border-radius: 50%;

      }

      &:not(:first-child) {
        margin-top: 3rem;
      }
    }
  }
}

.banner {
  margin-top: 5.5rem;
  width: 100%;
  height: 65rem;
  object-fit: cover;
}

.seeMore {
  margin-top: 8rem;
}
