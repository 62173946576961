@import "styles/variables";

.select {
  margin-top: 2rem;
}

.content {
  margin-top: 3.3rem;
}

.gallery {
  margin-top: 5rem;
}

.principles {
  margin-top: 4.5rem;
}

.stats {
  margin-top: 4.4rem;
}

@media (min-width: $widthTabletMin) {
  .select {
    margin-top: 2.5rem;
  }

  .content {
    margin-top: 3.3rem;
  }

  .gallery {
    margin-top: 6rem;
  }

  .principles {
    margin-top: 6rem;
  }

  .stats {
    margin-top: 6rem;
  }
}
