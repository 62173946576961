@import "styles/variables";
@import "styles/mixins";

.breadcrumbs {
  padding-top: 1.2rem;
  background-color: var(--color-highlight-lightest);
}

.container {
  display: flex;
  flex-direction: column;
  border-bottom: 0.3rem solid var(--color-highlight-mosaic);

  .text {
    order: 2;
    padding-top: 1.6rem;
    padding-bottom: 1.5rem;
  }

  .speciality {
    @include h8(var(--color-font-main));

    margin: 0.6rem 0 0 0;
    opacity: 0.7;
  }

  .frame {
    order: 1;
    position: relative;
    padding-top: 1.5rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -25vw;
      width: 150vw;
      height: 100%;
      background-color: var(--color-highlight-lightest);
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -70rem;
      width: 106rem;
      height: 29.3rem;
      background-image: url("../../../assets/icons/icon__doctor-banner-bg.svg");
      background-size: cover;
    }
  }

  .photo {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 25rem;
    height: 30rem;
    object-fit: contain;
    object-position: center bottom;
    z-index: 1;
  }

  .list {
    @include resetList;

    margin-top: 1.2rem;
  }

  .item {
    @include regular(var(--color-font-main));

    position: relative;
    display: flex;
    align-items: center;
    opacity: 0.7;

    &::before {
      content: "";
      display: inline-block;
      margin-right: 1.4rem;
      width: 2.4rem;
      height: 2.4rem;
      background-size: cover;
    }

    &:not(:first-child) {
      margin-top: 0.9rem;
    }
  }

  .itemAddress {
    &::before {
      background-image: url("../../../assets/icons/icon__location.svg");
    }
  }

  .itemReviews {
    &::before {
      background-image: url("../../../assets/icons/icon__speech.svg");
    }
  }

  .itemExperience {
    &::before {
      background-image: url("../../../assets/icons/icon__doctor.svg");
    }
  }

  .btn {
    margin-top: 2rem;
    background-color: rgb(119, 194, 255);
  }
}

@media (min-width: $widthTabletMin) {
  .breadcrumbs {
    padding-top: 3rem;
  }

  .container {
    position: relative;
    display: grid;
    grid-template-columns: 31rem 32.2rem;
    grid-column-gap: 1.1rem;
    background-color: var(--color-highlight-lightest);
    border-bottom: none;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 106rem;
      height: 29.3rem;
      background-image: url("../../../assets/icons/icon__doctor-banner-bg.svg");
      background-size: cover;
    }
  }

  .text {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    padding-top: 5.9rem;
    padding-bottom: 5.4rem;
  }

  .speciality {
    @include regularTabletScaling(var(--color-font-main));

    margin-top: 0.4rem;
  }

  .frame {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    margin-top: auto;

    &::before,
    &::after {
      display: none;
    }
  }

  .photo {
    position: static;
    width: 30rem;
    height: 35rem;
  }

  .list {
    margin-top: 3.7rem;
  }

  .item {
    &:not(:first-child) {
      margin-top: 1.6rem;
    }
  }
}
