@import "styles/variables";

.container {
  position: relative;
  margin-top: -4rem;
  padding-top: 3.8rem;
  padding-bottom: 6rem;
  background-color: $colorMainBg;
  border-top-right-radius: 4rem;
  border-top-left-radius: 4rem;

  .select {
    margin-top: 2rem;
  }

  .list {
    margin-top: 3rem;
  }

  .promo {
    margin-top: 4.8rem;
  }
}

@media (min-width: $widthTabletMin) {
  .container {
    .select {
      margin-top: 2.5rem;
    }

    .list {
      margin-top: 3.5rem;
    }

    .promo {
      margin-top: 6rem;
    }
  }
}
