@import "styles/variables";
@import "styles/mixins";

.rowDark {
  background-color: var(--color-highlight-lightest);
}


.key,
.value {
  @include h5(var(--color-font-main));

  padding: 2.8rem 4rem;
}

.key {
  display: flex;
  align-items: center;
}

.value {
  text-align: right;
}
