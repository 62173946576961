@import "styles/variables";

.table {
  margin-top: 2.3rem;
  margin-left: calc(-1 * var(--padding-mobile));
  width: calc(100% + 2 * var(--padding-mobile));
  border-collapse: collapse;
}

@media (min-width: $widthTabletMin) {
  .table {
    margin-top: 3rem;
    margin-left: 0;
    width: 100%;
  }
}
