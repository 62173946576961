@import "styles/variables";
@import "styles/mixins";


.button {
  @include resetButton;

  display: block;
  width: 100%;
  text-align: center;
  transition-duration: 0.3s;
  cursor: pointer;

  &.buttonFilled {
    @include capitals-bold($colorMainBg);

    padding: 2.2rem 0 1.8rem 0;
    background-color: var(--color-highlight-buttons);
  }

  &.buttonDecorated {
    @include capitals-bold(var(--color-highlight-buttons));

    position: relative;
    padding: 2rem 1rem;
    border: 1px solid var(--color-highlight-buttons);
    border-bottom-width: 0.5rem;
    overflow: hidden;

    .icon {
      margin-left: 1rem;
      width: 1.6rem;
      stroke: var(--color-highlight-buttons);
      transition-duration: 0.3s;
      transform: translateX(0);
    }
  }

  &.buttonFilledDecorated {
    color: $colorMainBg;
    background-color: var(--color-highlight-buttons);

    .icon {
      stroke: $colorMainBg;
    }
  }

  &.buttonGradient {
    @include capitals-bold($colorMainBg);

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4.6rem 4.3rem 4.4rem 5rem;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-highlight-mosaic);
      z-index: -1;
      transition-duration: 0.3s;
      transform: translateX(-29rem);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-highlight-mosaic);
      z-index: -1;
      opacity: 0.5;
    }

    .icon {
      margin-left: 1rem;
      width: 1.6rem;
      stroke: $colorMainBg;
      transition-duration: 0.3s;
      transform: translateX(0);
    }
  }
}

.buttonLoading {
  pointer-events: none;
  opacity: 0.5;
}

.spinner {
  margin-left: 1rem;
  width: 1.6rem;

  circle {
    fill: var(--color-highlight-buttons);
  }

  path {
    stroke: var(--color-highlight-buttons);
  }
}

@media (max-width: $widthTabletMax) {
  .button{
    &.buttonDecorated {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.9rem 2.3rem 1.5rem 3rem;
        text-align: left;

      .icon {
        margin-left: auto;
      }
    }
  }
}
