@import "styles/variables";
@import "styles/mixins";

.container {
  margin-top: -4rem;
  padding-top: 3.8rem;
  padding-bottom: 6rem;
  background-color: $colorMainBg;
  border-top-right-radius: 4rem;
  border-top-left-radius: 4rem;

  .select {
    position: relative;
    margin-top: 1.9rem;
    z-index: 2;
  }

  .content {
    padding-top: 2rem;
  }

  .promo {
    margin-top: 4.8rem;
  }
}

@media (min-width: $widthTabletMin) {
  .container {
    .select {
      margin-top: 2.5rem;
    }

    .content {
      padding-top: 3.5rem;
    }

    .promo {
      margin-top: 6rem;
    }
  }
}
