@import "styles/variables";
@import "styles/mixins";

.footer {
  position: relative;
  background-color: var(--color-font-main);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-highlight-darkest);
    opacity: 0.1;
  }
}

.top {
  position: relative;
  padding: 2.7rem 0 0 0;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: -25vw;
    width: 150vw;
    height: 1px;
    background-color: var(--color-highlight-darkest);
    opacity: 0.1;
  }
}

.socialList {
  @include resetList;

  display: flex;
  align-items: center;
}

.socialItem {
  &:not(:first-of-type) {
    margin-left: 1.2rem;
  }
}

.socialLink {
  font-size: 0;

  &:hover {
    .iconSocial {
      fill: var(--color-highlight-buttons);
      opacity: 1;
    }
  }
}

.iconSocial {
  width: 3rem;
  fill: var(--color-highlight-lightest);
  opacity: 0.3;
  transition-duration: 0.3s;
}

.bottom {
  display: flex;
  align-items: flex-start;
  padding: 2.1rem 0 3rem 0;
}

.copyright,
.counter {
  width: 50%;
}

.copyrightLink {
  &:hover {
    .logo {
      fill: var(--color-highlight-buttons);
      opacity: 1;
    }
  }
}

.label {
  @include h9-regular($colorMainBg);

  margin: 0;
  opacity: 0.5;
}

.logo {
  margin-top: 1rem;
  width: 8.4rem;
  fill: $colorMainBg;
  opacity: 0.5;
  transition-duration: 0.3s;
}

.metrics {
  width: 10.5rem;
}

.auxList,
.timeline,
.nav,
.workTime {
  display: none;
}

@media (min-width: $widthTabletMin) and (max-width: $widthTabletMax) {
  .wrapper {
    display: flex;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .top {
    padding: 0;

    &::before {
      display: none;
    }
  }

  .bottom {
    align-items: center;
    padding: 0;
    width: 100%;
  }

  .copyright,
  .counter {
    width: auto;
  }

  .copyright {
    order: 2;
    display: flex;
    align-items: center;
  }

  .label {
    @include small($colorMainBg);
  }

  .logo {
    margin-left: 1.4rem;
  }

  .counter {
    order: 1;
    margin: 0 auto;
  }

  .metrics {
    width: 9.3rem;
  }
}
