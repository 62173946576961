@import "styles/variables";

.wrapper {
  padding: 0 1.5rem;
}

.wrapperDecorated {
  position: relative;
  margin-top: -4rem;
  background-color: $colorMainBg;
  border-top-right-radius: 4rem;
  border-top-left-radius: 4rem;
}

@media (min-width: $widthTabletMin) {
  .wrapper {
    padding: 0 2.5rem;
  }
}
