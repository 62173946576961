@import "styles/variables";
@import "styles/mixins";

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  pointer-events: none;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-font-main);
    transition-duration: 0.6s;
    opacity: 0;
    transition-property: opacity;

  }
}

.wrapperVisible {
  pointer-events: all;

  &::before {
    opacity: 0.3;
  }

  button.btn {
    margin-top: 2.1rem;
    background-color: rgb(119, 194, 255);
  }
}

.content {
  --padding-right: 3rem;
  --padding-left: 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: auto;
  padding: 5.1rem var(--padding-right) 0 var(--padding-left);
  width: 28rem;
  height: 100vh;
  background-color: $colorMainBg;
  transition-duration: 0.3s;
  transform: translateX(100%);
  overflow-y: auto;
  z-index: 2;
}

.contentVisible {
  transform: translateX(0);
}

.close {
  @include resetButton;

  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.8rem;
  height: 2.8rem;
}

.iconClose {
  width: 2rem;
  stroke: var(--color-font-main);
}

.schedule {
  display: flex;
  align-items: center;
  margin: 0;
}

.iconClock {
  width: 2rem;
  fill: var(--color-font-main);
}

.scheduleLabel {
  @include h9-regular(var(--color-font-main));

  margin-left: 1.1rem;
}

.phoneList {
  @include resetList;
}

.phoneItem {
  margin-top: 0.8rem;
}

.phoneLink {
  @include h1(var(--color-font-main));
  font-weight: 400;
}

.switchColorsBtn {
  @include resetButton;
  @include h9-regular(var(--color-font-main));

  position: relative;
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  padding: 1.6rem 0 1.7rem 0;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(-1 * var(--padding-left));
    width: calc(100% + var(--padding-right) + var(--padding-left));
    height: 1px;
    background-color: var(--color-highlight-darker);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(-1 * var(--padding-left));
    width: calc(100% + var(--padding-right) + var(--padding-left));
    height: 1px;
    background-color: var(--color-highlight-darker);
  }
}

.iconEye {
  margin-right: 0.9rem;
  width: 2.4rem;
  stroke: var(--color-font-main);
}

.list {
  @include resetList;

  margin-top: 2.9rem;
}

.item {
  &:not(:first-of-type) {
    margin-top: 2.7rem;
  }
}

.link {
  @include capitals(var(--color-font-main));
}

.collapseBtn {
  @include resetButton;
  @include capitals(var(--color-font-main));

  display: flex;
  align-items: center;
  transition-duration: 0.3s;
  transition-property: color;
}

.collapseBtnOpened {
  color: var(--color-highlight-buttons);
}

.iconArrow {
  margin-left: 1rem;
  width: 0.8rem;
  fill: var(--color-font-main);
  transform: rotate(180deg);
  transition-duration: 0.3s;
}

.iconArrowOpened {
  transform: rotate(0);
  fill: var(--color-highlight-buttons);
}

.sublist {
  @include resetList;
  @include h9-regular(var(--color-font-main));

  padding-top: 2.4rem;
  padding-left: 1.4rem;
}

.subitem {
  &:not(:first-of-type) {
    margin-top: 2rem;
  }
}

.sublink {
  @include h9-regular(var(--color-font-main));
}

.auxList {
  @include resetList;

  position: relative;
  margin-top: 3.3rem;
  padding: 3.5rem 0 3.3rem 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(-1 * var(--padding-left));
    width: calc(100% + var(--padding-right) + var(--padding-left));
    height: 1px;
    background-color: var(--color-highlight-darker);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(-1 * var(--padding-left));
    width: calc(100% + var(--padding-right) + var(--padding-left));
    height: 1px;
    background-color: var(--color-highlight-darker);
  }
}

.auxItem {
  &:not(:first-of-type) {
    margin-top: 2.9rem;
  }
}

.auxLink {
  @include capitals(var(--color-font-main));
}

.timeline {
  @include capitals(var(--color-font-main));

  display: block;
  padding: 2.5rem 0;
  text-align: center;
  opacity: 0.5;
}

.bottom {
  margin-top: auto;
}

@media (min-width: $widthTabletMin) {
  .wrapperVisible {
    &::before {
      opacity: 0.5;
    }
  }

  .content {
    --padding-right: 8.6rem;
    --padding-left: 5.1rem;
    width: 58.2rem;
  }


  .close {
    top: 1.4rem;
    right: 1.6rem;
  }

  .top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .phoneItem {
    margin-top: 1.1rem;
  }

  button.btn {
    margin-top: 0.1rem;
    margin-left: 2rem;
    width: 19.3rem;
  }

  .switchColorsBtn {
    margin-top: 3.2rem;
    padding: 2rem 0 2.4rem 0;
  }

  .list {
    margin-top: 6.6rem;
  }

  .item {
    &:not(:first-of-type) {
      margin-top: 3rem;
    }
  }

  .auxList {
    margin-top: 5.3rem;
    padding: 5rem 0 5.7rem 0;
  }

  .auxItem {
    &:not(:first-of-type) {
      margin-top: 3rem;
    }
  }

  .timeline {
    @include small(var(--color-font-main));

    padding: 3.7rem 0 4.8rem 0;
  }
}
