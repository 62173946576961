.container {
  padding-top: 3.8rem;
  padding-bottom: 11rem;
}

.filters {
  margin-top: 3.8rem;
}

.promo {
  margin-top: 6.8rem;
}

.pricelist {
  .content {
    margin-top: 6rem;
  }
}
