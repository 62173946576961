@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.listWrapper {
  padding-left: 1.5rem;
  width: calc(100% + 1.5rem);
  overflow: scroll;
}

.list {
  @include resetList;

  display: flex;
  margin-top: 2.3rem;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 27rem;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 1rem;
  }

  &:last-child {
    padding-right: 1.5rem;
  }

  .trigger {
    width: 100%;
    height: 17.6rem;
    pointer-events: none;
  }

  .text {
    padding-top: 1.5rem;
    padding-right: 5%;
    width: 27rem;
  }

  .date {
    margin: 1rem 0 0 0;
  }
}

@media (min-width: $widthTabletMin) {
  .listWrapper {
    padding-left: 2.5rem;
    width: calc(100% + 2.5rem);
  }

  .list {
    margin-top: 2.5rem;
  }

  .item {
    width: 56.1rem;

    &:not(:first-child) {
      margin-left: 2rem;
    }

    &:last-child {
      padding-right: 2.5rem;
    }

    .text {
      padding-top: 1.7rem;
      width: 56.1rem;
    }

    .trigger {
      width: 100%;
      height: 34.1rem;
    }
  }
}
