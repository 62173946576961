@import "styles/mixins";

.section {
  padding-top: 4rem;
  padding-bottom: 11rem;
}

.list {
  @include resetList;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1.8rem;
  grid-row-gap: 4.4rem;
}

.buttonMore {
  margin-top: 4.4rem;
}

.empty {
  @include regular(var(--color-font-main));
  text-align: center;
  opacity: 0.5;
}
