@import "styles/variables";
@import "styles/mixins";

.container {
  padding-top: 9rem;
  padding-bottom: 11rem;

  .list {
    @include resetList;

    display: grid;
    grid-template-columns: repeat(3, 43.7rem);
    grid-column-gap: 3rem;
    grid-row-gap: 5rem;
    margin-top: 4.5rem;
  }

  .item {
    cursor: pointer;

    .trigger {
      pointer-events: none;
    }

    &:hover {
      .descriptor {
        color: var(--color-highlight-buttons);
      }
    }
  }

  .itemLarge {
    grid-column: 1 / -1;
    grid-row: 1 / 2;

    .trigger {
      width: 100%;
      height: 65rem;
    }
  }

  .item:not(.itemLarge) {
    .trigger {
      width: 100%;
      height: 27.4rem;
    }
  }

  .text {
    padding-top: 2rem;
  }

  .date {
    margin-top: 1rem;
  }

  .btnShowMore {
    margin-top: 3.7rem;
  }
}
