@import "styles/mixins";
@import "styles/variables";

.container {
  margin-top: 4.7rem;
  padding-bottom: 6rem;
}

.ads {
  margin-top: 2rem;
}

@media (min-width: $widthTabletMin) {
  .container {
    margin-top: 5rem;
    padding-bottom: 4rem;
  }

  .ads {
    margin-top: 2.2rem;
  }
}
