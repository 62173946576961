.container {
  display: grid;
  grid-template-columns: 102rem 32rem;
  justify-content: space-between;
}

.content {
  padding: 3.8rem 0 8.4rem 0;
}

.side {
  padding-top: 10.8rem;
  padding-bottom: 2rem;
  height: 100%;
}

.sticky {
  position: sticky;
  top: 10.8rem;
}

.fullscreen {
  display: block;
  width: 100%;
  height: 94rem;
  object-fit: cover;
}
