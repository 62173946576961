@import "styles/variables";
@import "styles/mixins";

.wrapper {
  padding-bottom: 10rem;
}

.title {
  @include h2(var(--color-font-main));

  margin: 0;
}

.sliderReviews {
  margin-top: 4.5rem;

  :global {
    .swiper-pagination {
      display: none;
    }

    .swiper-controls {
      top: -7.6rem;
    }
  }
}

.slide {
  display: flex;
  align-items: flex-start;
}

.card {
  position: relative;
  width: 43.7rem;

  &:not(:first-child) {
    margin-left: 2.9rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 5rem;
    left: 3rem;
    width: 43rem;
    height: 35.1rem;
    background-image: url("../../../assets/icons/doctor__photo-bg.svg");
    background-size: cover;
  }
}

.photo {
  position: relative;
  width: 100%;
  height: 46.2rem;
  z-index: 3;
  object-fit: contain;
}

.name {
  @include h5(var(--color-font-main));

  margin: 2rem 0 0 0;
}

.expertise {
  @include regular(var(--color-font-main));

  margin: 0;
  opacity: 0.7;
}

.address {
  @include regular(var(--color-font-main));

  margin: 1rem 0 0 0;
}
