@import "styles/variables";
@import "styles/mixins";

.button {
  @include resetButton;
  @include h9-regular(var(--color-highlight-lightest));

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition-duration: 0.3s;
  background-size: cover;
  cursor: pointer;

  &:hover {
    .icon {
      :global {
        .circle__outer {
          stroke: var(--color-highlight-mosaic)
        }

        .circle__inner {
          fill: var(--color-highlight-mosaic);
        }

        .icon__play {
          fill: var(--color-highlight-lightest);
        }
      }
    }
  }

  .icon {
    width: 5.7rem;

    :global {
      .circle__outer {
        stroke: var(--color-highlight-lightest);
        transition-duration: 0.3s;
      }

      .circle__inner {
        fill: var(--color-highlight-lightest);
        transition-duration: 0.3s;
      }

      .icon__play {
        fill: var(--color-highlight-mosaic);
        transition-duration: 0.3s;
      }
    }
  }

  .label {
    margin-top: 0.9rem;
  }
}

.button.buttonSquare {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 4rem;
    height: 4rem;
    background-color: $colorMainBg;
    transition-duration: 0.3s;
  }

  .iconSquare {
    position: absolute;
    bottom: calc(4rem - 5.7rem + 1.4rem / 2);
    right: calc(4rem - 5.7rem + 1.4rem / 2);

    :global {
      .circle__outer,
      .circle__inner {
        display: none;
      }
    }
  }

  &:hover {
    &::before {
      background-color: var(--color-highlight-mosaic);
    }
  }
}

.buttonMinified {
  width: 2.9rem;

  .icon {
    width: 100%;

    :global {
      .circle__outer {
        display: none;
      }

      .circle__inner {
        stroke: var(--color-highlight-mosaic);
        transition-duration: 0.3s;
      }
    }
  }
}
