@import "styles/variables";
@import "styles/mixins";

.list {
  @include resetList;

  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-highlight-darkest);
}

.item {
  display: flex;
  align-items: center;
  min-height: 6rem;

  &:not(:first-of-type) {
    border-top: 1px solid var(--color-highlight-darkest);
  }

  &:not(.itemActive) {
    .link:hover {
      color: var(--color-highlight-buttons);
      opacity: 1;
    }
  }
}

.link {
  @include h9(var(--color-font-main));

  display: block;
  padding: 2rem 3rem;
  width: 100%;
  opacity: 0.5;
  transition-duration: $transitionDuration;
}

.itemActive {
  .link {
    position: relative;
    font-weight: 600;
    opacity: 1;

    &::before {
      content: "";
      position: absolute;
      top: -0.1rem;
      left: 0;
      width: 0.3rem;
      height: calc(100% + 0.2rem);
      background-color: var(--color-highlight-mosaic);
      z-index: 1;
    }
  }
}
