@import "styles/mixins";

.container {
  @include mobileContainerLiftedDecorated;

  padding-top: 3.8rem;
  padding-bottom: 6rem;
}

.filters {
  margin-top: 2rem;
}

.promo {
  margin-top: 3rem;
}

.pricelist {
  .content {
    margin-top: 4.8rem;
  }
}

@media (min-width: $widthTabletMin) {
  .filters {
    margin-top: 2.5rem;
  }

  .promo {
    margin-top: 3.5rem;
  }

  .pricelist {
    .content {
      margin-top: 6rem;
    }
  }
}
