@import "styles/variables";
@import "styles/mixins";

.bannerWrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 1.1rem;
}

.bannerIcon {
  margin-top: 0.3rem;
  width: 2rem;
  fill: $colorMainBg;
  opacity: 0.5;
}

.bannerComment {
  @include small($colorMainBg);

  margin: 0 0 0 1.2rem;
}

.container {
  margin-top: -4rem;
  padding-top: 4rem;
  padding-bottom: 6rem;
  background-color: $colorMainBg;
  border-top-right-radius: 4rem;
  border-top-left-radius: 4rem;
}

.content {
  padding-top: 1.6rem;
  padding-bottom: 4.8rem;
}

@media (min-width: $widthTabletMin) {
  .bannerWrapper {
    align-items: center;
  }

  .bannerIcon {
    margin-top: 0;
  }

  .content {
    padding-top: 2.5rem;
    padding-bottom: 6rem;
  }
}
