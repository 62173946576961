@import "styles/variables";

.container {
  position: relative;
  margin-top: -4rem;
  border-top-right-radius: 4rem;
  border-top-left-radius: 4rem;
  background-color: $colorMainBg;
}

.content {
  padding: 3.8rem 0 5.8rem 0;
}

.fullscreen {
  display: block;
  width: 100%;
  height: 19rem;
  object-fit: cover;
}

.promo {
  margin-top: 4.8rem;
  padding-bottom: 6rem;
}

@media (min-width: $widthTabletMin) {
  .content {
    padding: 3.5rem 0 3.6rem 0;
  }

  .fullscreen {
    height: 37.7rem;
  }

  .promo {
    margin-top: 6rem;
  }
}
