@import "styles/mixins";
@import "styles/variables";

.link {
  display: block;

  &:hover {
    .name {
      color: var(--color-highlight-buttons);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  padding: 0;
}

.frame {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 46.2rem;

  &::before {
    content: "";
    position: absolute;
    top: 5rem;
    left: 3rem;
    width: 43rem;
    height: 35.1rem;
    background-image: url("../../assets/icons/doctor__photo-bg.svg");
    background-size: cover;
  }
}

.photo {
  position: relative;
  width: 100%;
  max-height: 45rem;
  object-fit: contain;
  object-position: center top;
}

.photoSmall {
  max-height: 42rem;
}

.name {
  @include h8(var(--color-font-main));

  margin: 2rem 0 0 0;
  transition-duration: $transitionDuration;
}

.expertise {
  @include regular(var(--color-font-main));

  margin: 0;
  opacity: 0.7;
}

.location {
  @include regular(var(--color-font-main));

  margin: 1rem 0 0 0;
}
