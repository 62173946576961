@import "styles/variables";
@import "styles/mixins";

.list {
  @include resetList;
}

.item {
  &:not(:first-of-type) {
    margin-top: 1rem;
  }
}

.buttonMore {
  margin-top: 3rem
}
