@import "styles/variables";
@import "styles/mixins";

.list {
  @include resetList;

  display: flex;
  flex-direction: column;

  .item {
    &:not(:first-of-type) {
      margin-top: 1rem;
    }
  }

  .vacancyWrapper {
    padding-top: 3.3rem;
    padding-bottom: 4.2rem;
  }

  .title {
    @include h9(var(--color-font-main), 600);

    margin: 0;

    &:not(:first-of-type) {
      padding-top: 2.5rem;
    }
  }

  .sublist {
    margin-top: 1rem;
  }
}

.buttonMore {
  margin-top: 3rem;
}
