@import "styles/variables";

.map {
  width: 100%;
  height: 46.2rem;
}

@media (min-width: $widthTabletMin) {
  .map {
    height: 45.6rem;
  }
}
