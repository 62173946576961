@import "styles/variables";
@import "styles/mixins";

.container {
  position: relative;
  display: grid;
  grid-template-columns: 44.4rem 50rem;
  grid-column-gap: 22.1rem;
  width: 100%;
  background-color: var(--color-highlight-lightest);
  overflow: hidden;
  min-height: 58.5rem;

  &::before {
    content: "";
    position: absolute;
    top: 5rem;
    left: 0;
    width: 100%;
    height: 53rem;
    background-image: url("../../../assets/icons/icon__doctor-banner-bg.svg");
    background-size: cover;
  }

  .text {
    padding-top: 4.6rem;
    padding-bottom: 11.6rem;
  }

  .breadcrumbs {
    padding: 0;
    width: 100%;
  }

  .title {
    margin-top: 7.6rem;
  }

  .speciality {
    @include h8(var(--color-font-main));

    margin: 2.5rem 0 0 0;
    opacity: 0.7;
  }

  .photo {
    position: absolute;
    bottom: 0;
    right: calc(50% - 50rem / 2 - 20rem);
    margin-top: auto;
    width: 55rem;
    height: 62rem;
    object-fit: contain;
    object-position: center bottom;
  }

  .list {
    @include resetList;

    margin-top: 4.6rem;
  }

  .item {
    @include regular(var(--color-font-main));

    position: relative;
    display: flex;
    align-items: center;
    opacity: 0.7;

    &::before {
      content: "";
      display: inline-block;
      margin-right: 1.4rem;
      width: 2.4rem;
      height: 2.4rem;
      background-size: cover;
    }

    &:not(:first-child) {
      margin-top: 1.9rem;
    }
  }

  .itemAddress {
    &::before {
      background-image: url("../../../assets/icons/icon__location.svg");
    }
  }

  .itemReviews {
    &::before {
      background-image: url("../../../assets/icons/icon__speech.svg");
    }
  }

  .itemExperience {
    &::before {
      background-image: url("../../../assets/icons/icon__doctor.svg");
    }
  }

  .controls {
    .button {
      margin-top: 3rem;
      width: 20rem;
      background-color: rgb(119, 194, 255);
      color: white;
      border-color: rgb(119, 194, 255);

      &:hover {
        background-color: rgb(119, 194, 255);
      }

      svg {
        stroke: white;
      }
    }
  }
}
