@import "styles/variables";
@import "styles/mixins";

.wrapper {
  margin-top: 2rem;
}

.card {
  &:not(:first-child) {
    margin-top: 2rem;
  }
}

.preview {
  width: 100%;
  height: 18.5rem;
  object-fit: cover;
}

.name {
  @include regular(var(--color-font-main), 600);

  display: flex;
  flex-direction: column;
  margin: 1.5rem 0 0 0;
}

.doctor {
  margin: 0;
}

.doctorInfo {
  @include capitals-bold(var(--color-font-main));

  margin: 0.9rem 0 0 0;
  padding: 0;
  opacity: 0.5;
}

.bannerWrapper {
  margin-left: calc(-1 * var(--padding-mobile));
  width: calc(100% + 2 * var(--padding-mobile));
  overflow: hidden;
}

.banner {
  position: relative;
  margin-top: 6rem;
  padding: 24.5rem 0 0 0;
  background-color: var(--color-highlight-lightest);

  .dotsTop {
    position: absolute;
    top: 0.5rem;
    left: 0;
    width: 100%;
    transform: rotate(0deg);
  }

  .photo {
    position: absolute;
    top: -3.4rem;
    left: var(--padding-mobile);
    width: calc(100% - 2 * var(--padding-mobile));
    height: 29.2rem;
    object-fit: cover;
  }

  .text {
    position: relative;
    padding: 2.4rem 3.2rem 2.7rem 3.2rem;
    background-color: var(--color-highlight-mosaic);
    border-top-right-radius: 4rem;
    border-top-left-radius: 4rem;
  }

  .quote {
    @include h4($colorMainBg);

    position: relative;
    margin: 0;
    padding: 0;

    &::before {
      content: "";
      position: absolute;
      top: -0.1rem;
      right: -0.3rem;
      width: 2.4rem;
      height: 2.1rem;
      background-image: url("../../../assets/icons/icon_quotes.svg");
      background-size: cover;
    }
  }

  .quoteName {
    @include regular($colorMainBg);

    margin: 1rem 0 0 0;
    opacity: 0.7;
  }
}

.list {
  margin-top: 2rem;
}

.listWrapper {
  display: flex;
  flex-direction: column;
}

.item {
  &:not(:first-child) {
    margin-top: 1.4rem;
  }
}

@media (min-width: $widthTabletMin) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 6rem;
    margin-top: 3.5rem;
  }

  .card {
    &:not(:first-child) {
      margin-top: 0;
    }
  }

  .bannerWrapper {
    grid-column: 1 / -1;
    margin-left: 0;
    width: 100%;
    border-bottom: 0.6rem solid var(--color-highlight-mosaic);
  }

  .banner {
    margin-top: 5.6rem;
    padding: 5.2rem 3rem 3.8rem 30rem;

    .dotsTop {
      position: absolute;
      top: 0;
      left: -3rem;
      width: 30rem;
      transform: rotate(180deg);
    }

    .photo {
      top: auto;
      bottom: 0;
      left: 0;
      width: 28.8rem;
      height: 29.1rem;
    }

    .text {
      position: relative;
      padding: 0;
      background-color: transparent;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .quote {
      @include h4(var(--color-font-main));

      &::before {
        top: -0.3rem;
        right: auto;
        left: calc(-1.8rem - 3.1rem);
        width: 3.1rem;
        height: 2.8rem;
        background-image: url("../../../assets/icons/icon_quotes--darker.svg");
      }
    }

    .quoteName {
      @include regular(var(--color-font-main));

      margin: 4.8rem 0 0 0;
    }
  }

  .list {
    grid-column: 1 / -1;
    margin-top: 0;
    margin-left: calc(-1 * var(--padding-tablet));
    padding: 0 var(--padding-tablet);
    width: calc(100% + 2 * var(--padding-tablet));
    overflow: scroll;
  }

  .listWrapper {
    flex-direction: row;
    width: max-content;
  }

  .item {
    &:not(:first-child) {
      margin-top: 0;
      margin-left: 2rem;
    }

    &:last-child {
      margin-right: var(--padding-tablet);
    }
  }
}
