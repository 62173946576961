@import "styles/variables";
@import "styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 14.8rem;
  padding-bottom: 19rem;
}

.code {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -1rem;
    left: 19.2rem;
    width: 33.9rem;
    height: 33.9rem;
    background-image: url("../../assets/images/page404__tooth.png");
    background-size: cover;
    z-index: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: -4rem;
    left: 15.2rem;
    width: 37.9rem;
    height: 40rem;
    background-image: url("../../assets/icons/page404__dots.svg");
    background-size: cover;
    z-index: -1;
  }
}

.number {
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 30rem;
  font-weight: 600;
  line-height: 30rem;
  color: var(--color-highlight-darker);

  &:not(:first-child) {
    margin-left: 30rem;
  }
}

.descriptor {
  @include h2(var(--color-font-main));

  margin: 7rem 0 0 0;
}

.details {
  @include regular(var(--color-font-main));

  margin: 2.9rem 0 0 0;
  text-align: center;
}

.list {
  @include resetList;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.item {
  &:not(:first-child) {
    margin-left: 4rem;
  }
}

.link {
  @include capitals-bold(var(--color-highlight-buttons));

  display: flex;
  align-items: center;
  transition-duration: $transitionDuration;

  &:hover {
    color: var(--color-highlight-buttons-dark);

    .icon {
      stroke: var(--color-highlight-buttons-dark);
    }
  }

  &:active {
    color: var(--color-highlight-buttons);
    opacity: 0.7;

    .icon {
      stroke: var(--color-highlight-buttons);
      opacity: 0.7;
    }
  }
}

.icon {
  margin-left: 1rem;
  width: 1.5rem;
  stroke: var(--color-highlight-buttons);
  transition-duration: $transitionDuration;
}
