@import "styles/variables";
@import "styles/mixins";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: $colorMainBg;
  z-index: 3;
}

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  overflow: hidden;
}

.logo {
  margin-right: auto;
}

.iconLogo {
  width: 4rem;
  fill: var(--color-font-main);
}

.search {
  @include resetButton;

  margin-left: auto;
}

.iconSearch {
  width: 1.9rem;
  fill: var(--color-font-main);
}

.burger {
  @include resetButton;

  margin-left: 2rem;
}

.iconBurger {
  width: 2.2rem;
  fill: var(--color-font-main);
}

.btnRegister,
.top {
  display: none;
}

@media (min-width: $widthTabletMin) {
  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
  }

  .wrapper {
    padding: 2.3rem 0 2.1rem 0;
  }

  .headerTabletTransparent {
    background-color: transparent;

    .iconLogo,
    .iconSearch,
    .iconBurger {
      fill: $colorMainBg;
    }
  }
}
