@import "styles/variables";
@import "styles/mixins";

.section {
  margin-top: 4.4rem;
  padding-bottom: 8.3rem;
}

.duration {
  @include regular(var(--color-font-main));

  margin: 1.8rem 0 0 0;
  opacity: 0.5;
}

.descriptor {
  @include regular(var(--color-font-main));

  margin: 3.5rem 0 0 0;
}

.image {
  display: block;
  margin-top: 4.8rem;
  width: 100%;
  height: 53.7rem;
  object-fit: cover;
}
