@import "styles/mixins";
@import "styles/variables";

.container {
  margin-top: 10rem;
  padding-bottom: 12.5rem;
}

.tabs {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 3.5rem;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-highlight-darker);
  }
}

.tab {
  @include resetButton;
  @include capitals-bold(var(--color-font-main));

  position: relative;
  padding: 2.5rem;
  width: 100%;
  transition-duration: 0.15s;
  opacity: 0.5;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.3rem;
    background-color: var(--color-font-main);
    transition-duration: 0.3s;
    opacity: 0;
  }

  &:hover {
    opacity: 1;
  }
}

.tabActive {
  opacity: 1;
  transition-delay: 0.15s;
  transition-duration: 0.3s;

  &::before {
    opacity: 1;
  }
}

.wrapper {
  display: flex;
  margin-top: 5rem;
}

.tabContent {
  flex-grow: 1;
  margin-right: 4.9rem;
  width: 100%;
}

.tabBlock {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition-duration: 0.15s;
}

.tabBlockActive {
  opacity: 1;
  pointer-events: all;
  transition-delay: 0.15s;
  transition-duration: 0.15s;
}

.sublist {
  @include resetList;

  display: grid;
  grid-template-columns: repeat(2, 43.7rem);
  grid-auto-rows: minmax(9.2rem, 1fr);
  grid-gap: 1rem;
}

.link {
  @include regular(var(--color-font-main));

  display: flex;
  align-items: center;
  padding: 2rem 4rem;
  height: 100%;
  background-color: var(--color-highlight-lightest);
  transition-duration: $transitionDuration;
  transition-property: background-color;

  &:hover {
    background-color: transparent;
  }
}

.promo {
  width: 43.7rem;

  .slide {
    width: 43.7rem;

    &:not(:last-of-type) {
      margin-right: 0;
    }
  }
}
