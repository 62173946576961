@import "../../styles/variables";
@import "../../styles/mixins";

.link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem 4rem;
  min-height: 40rem;
  overflow: hidden;

  &.linkTeeth {
    &::before {
      content: "";
      position: absolute;
      bottom: 5.7rem;
      right: 0;
      width: 24rem;
      height: 14.8rem;
      background-image: url("../../assets/images/teeth.png");
      background-size: cover;
      z-index: 1;
    }
  }

  &.linkImplant {
    &::before {
      content: "";
      position: absolute;
      bottom: 3.1rem;
      right: 0.5rem;
      width: 23.7rem;
      height: 14.6rem;
      background-image: url("../../assets/images/implant.png");
      background-size: cover;
      z-index: 1;
    }
  }

  &.linkJaw {
    &::before {
      content: "";
      position: absolute;
      bottom: 1rem;
      right: 2.3rem;
      width: 15rem;
      height: 17rem;
      background-image: url("../../assets/images/jaw.png");
      background-size: cover;
      z-index: 1;
    }
  }

  &.linkTherapy {
    &::before {
      content: "";
      position: absolute;
      bottom: 1rem;
      right: 0;
      width: 19rem;
      height: 19rem;
      background-image: url("../../assets/images/therapy.png");
      background-size: cover;
      z-index: 1;
    }
  }
}

.duration {
  @include capitals-bold($colorMainBg);

  position: relative;
  display: block;
  z-index: 1;
}

.title {
  @include h3($colorMainBg);

  margin: 5.4rem 0 0 0;
  padding-right: 20%;
  width: 100%;
  letter-spacing: -1px;

}

.price {
  @include regular($colorMainBg);

  display: block;
  margin-top: 1.9rem;

  span {
    font-weight: 600;
  }
}

.label {
  @include capitals-bold($colorMainBg);

  display: flex;
  align-items: center;
  margin-top: auto;
}

.icon {
  margin-left: 1.6rem;
  width: 1.6rem;
  stroke: $colorMainBg;
}

.bg {
  position: absolute;
  top: 1rem;
  left: 0.4rem;
  width: 43rem;
}

.thin {
  padding: 2rem 2.5rem 2.2rem;

  &.linkTeeth {
    &::before {
      bottom: 5.7rem;
      right: 2.4rem;
      width: 14.5rem;
      height: 9.9rem;
      background-size: cover;
    }
  }

  &.linkImplant {
    &::before {
      bottom: 6rem;
      right: 0.5rem;
      width: calc(23.7rem * 0.85);
      height: calc(14.6rem * 0.85);
    }
  }

  &.linkJaw {
    &::before {
      bottom: 0.5rem;
      right: 1rem;
      width: calc(17.1rem * 0.8);
      height: calc(19.2rem * 0.8);
    }
  }

  .title {
    @include h4($colorMainBg);

    margin: 4.9rem 0 0 0;
    padding-right: 0;
    font-weight: 600;
  }

  .price {
    margin-top: 1rem;
  }
}

.compact {
  padding: 2.8rem 2.5rem 5.7rem 2.5rem;
  min-height: 30rem;

  &.linkTeeth {
    &::before {
      bottom: 0.5rem;
      right: 0;
      width: 16.4rem;
      height: 13.9rem;
    }
  }

  &.linkImplant {
    &::before {
      bottom: 0.4rem;
      right: -7.9rem;
      width: 23.7rem;
      height: 14.6rem;
    }
  }

  &.linkJaw {
    &::before {
      bottom: 0.5rem;
      right: 1rem;
      width: 15.6rem;
      height: 17.5rem;
    }
  }

  .title {
    @include h3($colorMainBg);

    margin: 2.9rem 0 0 0;
  }

  .price {
    margin-top: 1rem;
  }

  .bg {
    top: 1rem;
    left: 0.4rem;
    width: 100%;
  }
}
