@import "styles/variables";
@import "styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  padding-top: 2.9rem;
  background-color: var(--color-highlight-lightest);

  .date {
    margin: 0.6rem 0 0 0;
  }
}

.list {
  @include resetList;

  padding-bottom: 3.9rem;
}

.item {
  &:not(:first-child) {
    margin-top: 1.2rem;
  }
}

.link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 10%;
}

.image {
  display: block;
  margin-left: -1.5rem;
  width: calc(100% + 2 * 1.5rem);
  height: 19rem;
  object-fit: cover;
}

@media (min-width: $widthTabletMin) {
  .container {
    display: flex;
    flex-direction: row;
    padding-top: 6rem;
    padding-bottom: 5rem;

    .date {
      margin: 1.5rem 0 0 0;
    }
  }

  .list {
    padding-bottom: 0;
  }

  .item {
    &:not(:first-child) {
      margin-top: 2.4rem;
    }
  }

  .link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 10%;
  }

  .image {
    display: block;
    margin-left: 0;
    width: 42.8rem;
    height: 39.1rem;
  }
}
