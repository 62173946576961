@import "styles/variables";
@import "styles/mixins";

.item {
  @include resetButton;

  display: flex;
  flex-direction: column;
  width: 100%;
}

.preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.label {
  @include regular(var(--color-font-main));

  margin: 2rem 0 0 0;
  text-align: left;
}
