@import "styles/variables";
@import "styles/mixins";

.list {
  @include resetList;

  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 1rem;

  & + .buttonMore {
    margin-top: 3rem;
  }
}

@media (min-width: $widthTabletMin) {
  .list {
    grid-template-columns: repeat(2, 34.5rem);
    justify-content: space-between;
  }
}
