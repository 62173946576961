@import "styles/mixins";
@import "styles/variables";

.link {
  display: block;

  &:hover {
    .name {
      color: var(--color-highlight-buttons);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
  }

  .frame {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 29rem;

    &::before {
      content: "";
      position: absolute;
      top: -1.1rem;
      left: 0;
      width: 100%;
      height: 25.6rem;
      background-image: url("../../assets/icons/doctor__photo-bg.svg");
      background-size: cover;
    }
  }

  .photo {
    position: relative;
    height: 100%;
    font-size: 0;
    object-fit: contain;
  }

  .name {
    margin: 1.2rem 0 0 0;
    font-family: "Montserrat", sans-serif;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: var(--color-font-main);
    transition-duration: $transitionDuration;
  }

  .expertise {
    @include regular(var(--color-font-main));

    margin: 0;
    opacity: 0.7;
  }

  .location {
    @include regular(var(--color-font-main));

    margin: 0.8rem 0 0 0;
  }
}
