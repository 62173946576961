@import "styles/variables";
@import "styles/mixins";

.tab {
  @include resetButton;
  @include capitals-bold(var(--color-font-main));

  padding: 2.1rem 4.6rem;
  text-align: left;
  opacity: 0.5;
  transition-duration: $transitionDuration;

  &:hover {
    opacity: 1;
  }
}

.tabActive {
  position: relative;
  opacity: 1;

  &::before {
    content: "";
    position: absolute;
    bottom: -0.1rem;
    left: 0;
    width: 100%;
    height: 0.3rem;
    background-color: var(--color-font-main);
  }
}
