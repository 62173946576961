@import "styles/variables";
@import "styles/mixins";

.container {
  padding-top: 3.8rem;
}

.content {
  padding-top: 5rem;
  padding-bottom: 11rem;
}
