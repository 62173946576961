@import "styles/variables";
@import "styles/mixins";

.footer {
  position: relative;
  background-color: var(--color-font-main);
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-highlight-darkest);
    opacity: 0.1;
  }

  .wrapper {
    padding-top: 7.6rem;
    padding-bottom: 0;
  }

  .top {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-bottom: 3.4rem;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: -25vw;
      width: 150vw;
      height: 1px;
      background-color: var(--color-highlight-darkest);
      opacity: 0.1;
    }
  }

  .nav {
    display: flex;

    .navList {
      @include resetList;

      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .navItem {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .navLabel {
      margin: 0;
      width: 100%;
    }

    .navSublist {
      @include resetList;

      margin-top: 2.5rem;

      &:not(:first-of-type) {
        margin-left: 10rem;
      }
    }

    .subItem {
      &:not(:first-of-type) {
        margin-top: 2rem;
      }
    }

    .subLink {
      @include regular(var(--color-highlight-lightest));
      @include linkBehavior;
    }

    .navSingles {
      @include resetList;
    }

    .navSingle {
      &:not(:first-of-type) {
        margin-top: 2.2rem;
      }
    }

    .navSingleLink {
      @include linkBehavior;
    }
  }

  .info {
    margin-left: auto;
  }

  .workTime {
    display: block;
  }

  .highlight {
    @include capitals-bold(var(--color-highlight-lightest));

    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }

  .time {
    @include h9-regular(var(--color-highlight-lightest));

    display: block;
    margin-top: 1.3rem;
  }

  .socialList {
    margin-top: 5.9rem;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.1rem 0 3rem 0;
  }

  .counter {
    order: 1;
  }

  .metrics {
    width: 10.5rem;
  }

  .timeline {
    @include h9-regular(var(--color-highlight-lightest));

    display: block;
  }

  .auxList {
    @include resetList;

    order: 2;
    display: flex;
    align-items: center;
  }

  .auxItem {
    &:not(:first-of-type) {
      margin-left: 12.2rem;
    }
  }

  .auxLink {
    @include h9-regular(var(--color-highlight-lightest));
    @include linkBehavior;
  }

  .copyrightLink {
    &:hover {
      .logo {
        opacity: 0.25;
      }
    }
  }

  .copyright {
    order: 3;
    display: flex;
    align-items: center;
  }

  .label {
    @include h9-regular($colorMainBg);

    margin: 0;
    opacity: 0.5;
  }

  .logo {
    margin-top: 1rem;
    margin-left: 2.3rem;
    width: 10.6rem;
    fill: $colorMainBg;
    opacity: 0.5;
    transition-duration: 0.3s;
  }
}