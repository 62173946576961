@import "styles/variables";
@import "styles/mixins";

.descriptor {
  @include h5-italic(var(--color-font-main));

  margin: 3rem 0 0 0;
}

.list {
  @include resetList;

  display: grid;
  grid-template-columns: 27.3rem 1fr 27.3rem;
  margin-top: 4.7rem;
}

.item {
  display: flex;
  align-items: center;

  &:nth-of-type(2n) {
    justify-content: center;
    border-right: 1px solid var(--color-highlight-darker);
    border-left: 1px solid var(--color-highlight-darker);
  }

  &:last-child {
    justify-content: flex-end;
  }
}

.key {
  @include counter(var(--color-highlight-mosaic));
  margin: 0;

  display: flex;
}

.value {
  @include regular(var(--color-font-main));
  margin: 0;
}
