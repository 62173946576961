@import "styles/variables";
@import "styles/mixins";

.wrapper {
  @include overlay;

  background-size: cover;
  background-position: 75% top;
}

.container {
  padding-top: 7rem;
  padding-bottom: calc(4rem + 3.2rem);
  height: 20rem;
}

.title {
  @include h1($colorMainBg);

  margin: 0;
}

.special {
  @include h5($colorMainBg);

  margin: 1.7rem 0 0;
}

.descriptor {
  @include h5($colorMainBg);

  margin: 1.7rem 0 0 0;
}

@media (min-width: $widthTabletMin) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 30rem;
    padding-top: calc(7.3rem + 2.5rem);
    padding-bottom: calc(4rem + 3.9rem);
  }

  .special {
    font-size: 2rem;
    line-height: 4rem;

    margin: 0.8rem 0 0;

    & + .descriptor {
      margin: 0;
    }

  }

  .descriptor {
    padding-right: 5%;
  }
}
