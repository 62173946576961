@import "styles/variables";
@import "styles/mixins";

.card {
  margin: 0;
  padding: 0;
  font-size: 0;
}

.image {
  width: 100%;
  height: 20.6rem;
  font-size: 0;
  object-fit: cover;
}

.label {
  @include regular(var(--color-font-main));

  margin-top: 1.3rem;
}

@media (min-width: $widthTabletMin) {
  .label {
    margin-top: 1.9rem;
  }
}
