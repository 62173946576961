@import "styles/variables";
@import "styles/mixins";

.wrapper {
  position: relative;
  border: 1px solid var(--color-highlight-darker);
  cursor: pointer;
  transition-duration: $transitionDuration;

  &::before {
    content: "";
    position: absolute;
    top: 4.5rem;
    right: 4rem;
    width: 1.4rem;
    height: 1px;
    background-color: var(--color-font-main);
  }

  &::after {
    content: "";
    position: absolute;
    top: 4.5rem;
    right: 4rem;
    width: 1.4rem;
    height: 1px;
    background-color: var(--color-font-main);
    transform: rotate(90deg);
    transform-origin: center;
    transition-duration: $transitionDuration;
  }

  &:not(.wrapperOpened):hover {
    border-color: var(--color-highlight-buttons);

    &::before,
    &::after {
      background-color: var(--color-highlight-buttons);
    }

    .key {
      color: var(--color-highlight-buttons);
    }
  }
}

.key {
  @include h6(var(--color-font-main));

  position: relative;
  margin: 0;
  padding: 3.4rem 12.7rem 3.1rem 4rem;
  transition-duration: $transitionDuration;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  transform: translateY(-100%);
  opacity: 0;
  transition-duration: 500ms;
}

.value {
  @include regular(var(--color-font-main));

  margin: 0;
  padding: 0 6rem 2rem 4rem;

  p {
    margin: 0;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }
}

.wrapperOpened {
  &::after {
    transform: rotate(0);
  }

  .key {
    color: var(--color-highlight-buttons);
  }
}

.wrapperOpenedBackground {
  &::before,
  &::after {
    background-color: $colorMainBg;
  }

  .key {
    color: $colorMainBg;
  }

  .background {
    transform: translateY(0);
    opacity: 1;
  }
}
