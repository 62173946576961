@import "styles/variables";
@import "styles/mixins";


.bg {
  position: relative;
  height: 42.4rem;
  background-size: cover;
  background-position: center bottom;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(59, 59, 59);
    opacity: 0.6;
  }
}

.top {
  margin-top: -30.9rem;
}

.content {
  position: relative;
  margin-top: 4.5rem;
  padding-top: 3rem;
  background-color: $colorMainBg;
  border-top-right-radius: 4rem;
  border-top-left-radius: 4rem;
}

button.play {
  margin: 0 auto;
}

.sloganAdaptive {
  @include h1(var(--color-font-main));

  margin: 0;
  padding-right: 15%;
  letter-spacing: -2px;
}

.sloganDesktop {
  display: none;
}

.descriptor {
  @include h9-regular(var(--color-font-main));

  margin: 1.7rem 0 0 0;
  opacity: 0.7;
}

.btn {
  margin-top: 2.2rem;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videoBg,
.dots {
  display: none;
}

@media (max-width: $widthMobileMax) {
  div.container {
    padding: 0 3rem;
  }
}

@media (min-width: $widthTabletMin) {
  .bg {
    height: 50rem;
  }

  .top {
    margin-top: -32.5rem;
  }

  .content {
    margin-top: 12.4rem;
    padding-top: 5rem;
  }

  .sloganAdaptive {
    padding-right: 0;
  }

  .btn {
    margin-top: 2rem;
    width: 25.5rem;
  }
}
