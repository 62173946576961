@import "styles/variables";
@import "styles/mixins";

.wrapper {
  position: relative;
  height: 27.4rem;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(38, 42, 19);
    opacity: 0.5;
  }

  & > * {
    position: relative;
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 6rem;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 8.7rem;
  padding-bottom: 6.6rem;
  height: 100%;
}

.containerSpecial {
  grid-template-columns: 1fr 68.2rem;
  grid-column-gap: 27rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.title {
  @include h1($colorMainBg);

  margin: 0;
}

.textWrapper {
  display: flex;
  flex-direction: column;

  .special {
    @include h4($colorMainBg);

    margin: 0;
  }
}

.descriptor {
  @include h5($colorMainBg);

  margin: 0;
}
