@import "styles/variables";
@import "styles/mixins";

.title {
  @include h2(var(--color-font-main));

  margin: 0;
}

.expertiseList {
  @include resetList;

  margin-top: 3.2rem;
}

.expertiseItem {
  @include h5(var(--color-font-main));

  position: relative;
  padding-left: 1.7rem;

  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 0.5rem / 2);
    left: 0;
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--color-highlight-buttons);
    border-radius: 50%;
  }

  &:not(:first-of-type) {
    margin-top: 2.1rem;
  }
}

.quote {
  @include h5-italic(var(--color-font-main));

  margin: 4rem 0 0 0;
  padding: 0 5% 0 0;
  opacity: 0.7;
}

.playWrapper {
  position: relative;
  margin-top: 4.6rem;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.6rem;
    background-color: var(--color-highlight-mosaic);
    z-index: 1;
  }
}

.play {
  width: 100%;
  height: 50rem;
}

.listDef {
  margin-top: 4.7rem;
  padding-right: 10%;
}

.key,
.value {
  margin: 0;
  padding: 0;
}

.key {
  @include h6(var(--color-font-main));

  &:not(:first-of-type) {
    margin-top: 2.5rem;
  }
}

.value {
  @include regular(var(--color-font-main));

  margin-top: 0.8rem;
}

.slider {
  margin-top: 4.7rem;

  :global {
    .swiper-pagination {
      display: none;
    }

    .swiper-controls {
      top: -7.6rem;
    }
  }
}

.slide {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.4rem;

  .image {
    align-self: center;
    width: 100%;
    max-height: 40rem;
    object-fit: contain;
  }
}

.sliderReviews {
  margin-top: 4.5rem;

  :global {
    div.swiper-pagination {
      width: 100%;
    }

    .swiper-controls {
      top: -7.6rem;
    }
  }
}

.review {
  padding: 4.4rem 5.4rem 3.6rem 5rem;
  background-color: var(--color-highlight-lightest);

  &Intro {
    display: flex;
    align-items: flex-start;
  }

  .name,
  .date {
    @include h6(var(--color-font-main));

    margin: 0;
  }

  .date {
    margin-left: 1rem;
  }

  &Content {
    @include regular(var(--color-font-main));

    margin: 1.6rem 0 0 0;
  }

  &Info {
    display: flex;
    align-items: flex-start;
    margin-top: 3.6rem;
  }

  &Expertise,
  &Specialist {
    @include h7(var(--color-font-main));

    margin: 0;
    opacity: 0.5;
  }

  &Expertise {
    margin-right: 3.8rem;
  }
}

.text {
  display: grid;
  grid-template-columns: 46.5rem 48.8rem;
  grid-column-gap: 2.9rem;
  margin-top: 4.2rem;

  div > p {
    @include regular(var(--color-font-main));

    margin: 0;

    &:not(:first-of-type) {
      margin-top: 2rem;
    }
  }
}

.portfolioList {
  @include resetList;

  display: grid;
  grid-template-columns: repeat(3, 32rem);
  grid-gap: 3rem;
}

.table {
  margin-top: 4.8rem;
  width: 100%;
  border-collapse: collapse;
}

.advisedList {
  @include resetList;

  display: grid;
  grid-template-columns: repeat(3, 32rem);
  grid-gap: 3rem;
  margin-top: 4.5rem;
}

.accordeons {
  @include resetList;

  margin-top: 4.5rem;
}

.accordeon {
  &:not(:first-child) {
    margin-top: 1rem;
  }
}