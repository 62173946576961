@import "styles/variables";
@import "styles/mixins";

.list {
  @include resetList;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 3rem;
  grid-row-gap: 1rem;

  & + .buttonMore {
    margin-top: 3rem;
  }
}
