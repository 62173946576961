@import "styles/variables";
@import "styles/mixins";

.cities {
  display: flex;
  align-items: flex-start;
  margin-top: 4.1rem;

  .city {
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}

.locations {
  position: relative;
  margin-top: 1.7rem;

  :global {
    .swiper-slide {
      width: 25%;
    }
  }
}

.button {
  @include resetButton;

  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 3rem;
  height: 100%;
  z-index: 2;

  &:hover {
    .icon {
      stroke: var(--color-highlight-buttons);
    }
  }

  .icon {
    width: 1rem;
    stroke: var(--color-font-main);
    transition-duration: $transitionDuration;
  }
}

.buttonPrev {
  left: 0;
  transform: rotate(180deg);
}

.buttonNext {
  right: 0;
}

.buttonDisabled {
  display: none;
}

.content {
  margin-top: 5rem;
  padding-bottom: 11rem;
  min-height: 65rem;
}
