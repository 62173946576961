@import "styles/mixins";
@import "styles/variables";

.buttonReview {
  @include resetButton;
  @include capitals-bold($colorMainBg);

  width: max-content;
  margin-top: 1.4rem;
  transition-duration: $transitionDuration;
}

.bannerIcon {
  margin-left: 1rem;
  width: 1.5rem;
  stroke: $colorMainBg;
  transition-duration: $transitionDuration;
}

.container {
  position: relative;
  margin-top: -4rem;
  padding-top: 3.8rem;
  padding-bottom: 6rem;
  background-color: $colorMainBg;
  border-top-right-radius: 4rem;
  border-top-left-radius: 4rem;
}
