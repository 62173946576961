.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.wrapperPadding {
  padding-top: 5.5rem;
}

.footer {
  margin-top: auto;
}
