@import "styles/variables";
@import "styles/mixins";

.wrapper {
  display: grid;
  grid-template-columns: 1fr 74rem;
  grid-column-gap: 5.6rem;

  :global {
    div.swiper-pagination {
      width: calc(100% - 15rem);
    }
  }
}

.info {
  margin-top: 4rem;
}

.list {
  @include resetList;
}

.item {
  @include regular(var(--color-font-main));

  display: flex;
  align-items: center;
  opacity: 0.7;

  &:not(:first-child),
  &Email {
    margin-top: 1.4rem;
  }

  &Email {
    transition-duration: $transitionDuration;

    .icon {
      transition-duration: $transitionDuration;
    }

    &:hover {
      color: var(--color-highlight-buttons);

      .icon {
        fill: var(--color-highlight-buttons);
      }
    }
  }
}

.icon {
  margin-right: 1.2rem;
  width: 2.4rem;
  fill: var(--color-font-main);
  opacity: 0.5;
}

.iconSchedule,
.iconPhone {
  opacity: 1;
}

.slide {
  width: 100%;
  height: 48.5rem;
  object-fit: cover;
}
