@import "styles/variables";

.wrapper {
  position: relative;
  width: 2rem;
  height: 3rem;
  background-color: var(--color-highlight-lightest);
}

.icon {
  position: absolute;
  top: calc(50% - 0.9rem / 2);
  right: -1rem;
  width: 1.5rem;
  stroke: var(--color-font-main);
}
